import { useDispatch, useSelector } from "react-redux";
import {
  ScreenContentItem,
  ScreenContentSet,
  ScreenInsights,
} from "../../../../interfaces/insights/screen.interface";
import { Checkbox } from "../../../input/checkbox/Checkbox";
import styles from "./Insights.module.scss";
import Loading from "../../../loading/Loading";
import { Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { toggleChartCollapse } from "../../../../redux/actions/screensActions";
import allActions from "../../../../redux/actions/allActions";

// Dropdown Options for Available Insight Graphs
export const ChartVisibilityMenu = () => {
  const dispatch = useDispatch();

  const screenInsights: ScreenInsights[] = useSelector(
    (state: any) => state.screens
  ).screenInsights;

  // Dropdown Collasable Header
  const CollapsableHeader = (screens: ScreenInsights) => (
    <div
      className={styles.dropdown_section__title}
      onClick={() => dispatch(toggleChartCollapse({ screenInsights: screens }))}
    >
      <div>{screens.title}</div>
      <div className={styles.filler}></div>
      <FontAwesomeIcon
        icon={screens.isCollapsed ? solid("caret-down") : solid("caret-up")}
      />
    </div>
  );

  // Subheader for options with more options
  const CollapsableOption = (
    screens: ScreenInsights,
    set: ScreenContentSet,
    screenInsights: ScreenInsights
  ) => (
    <div
      className={styles.dropdown_section__title}
      style={{ paddingLeft: "15px" }}
    >
      <Checkbox
        defaultChecked={set.isVisible}
        title={set.label}
        onChange={() => {
          dispatch(
            allActions.screensActions.setScreenVisibility({
              isVisible: !set.isVisible,
              screenIndex: screens.sequence,
              setIndex: set.sequence,
              itemIndex: null,
            })
          );
        }}
      />
      <div
        style={{ width: "100%", display: "flex" }}
        onClick={() =>
          dispatch(
            allActions.screensActions.toggleSubChartCollapse({
              set: set,
              screenInsights: screenInsights,
            })
          )
        }
      >
        <div className={styles.filler}></div>
        <FontAwesomeIcon
          icon={set.isCollapsed ? solid("caret-down") : solid("caret-up")}
        />
      </div>
    </div>
  );

  // Options in a Collapsable
  const Options = (screens: ScreenInsights) => (
    <Collapse
      isOpen={!screens.isCollapsed}
      className={styles.dropdown_chart__checkbox}
    >
      {screens.sets.map((set: ScreenContentSet, setIndex: number) => {
        // Screen Title Page
        if (setIndex === 0 || set.items.length === 0)
          return (
            <div
              key={set.label + "__" + set.index}
              style={{ paddingLeft: setIndex === 0 ? "0px" : "15px" }}
            >
              <Checkbox
                title={set.label}
                defaultChecked={set.isVisible}
                onChange={() => {
                  dispatch(
                    allActions.screensActions.setScreenVisibility({
                      isVisible: !set.isVisible,
                      screenIndex: screens.sequence,
                      setIndex: set.sequence,
                      itemIndex: null,
                    })
                  );
                }}
              />
            </div>
          );
        //  Set with Items
        else {
          const hasHeader = (
            <>
              {CollapsableOption(screens, set, screens)}
              <Collapse
                isOpen={!set.isCollapsed}
                style={{ paddingLeft: "30px" }}
              >
                {set.items.map((item: ScreenContentItem) => {
                  return (
                    <Checkbox
                      key={"Chart_" + item.label + "__" + item.index}
                      title={item.label}
                      defaultChecked={item.isVisible}
                      onChange={() => {
                        dispatch(
                          allActions.screensActions.setScreenVisibility({
                            isVisible: !item.isVisible,
                            screenIndex: screens.sequence,
                            setIndex: set.sequence,
                            itemIndex: item.sequence,
                          })
                        );
                      }}
                    />
                  );
                })}
              </Collapse>
            </>
          );

          // Only Items
          const noHeader = set.items.map((item: ScreenContentItem) => {
            return (
              <div
                style={{ paddingLeft: "15px" }}
                key={item.label + "__" + item.index}
              >
                <Checkbox
                  title={item.label}
                  defaultChecked={item.isVisible}
                  onChange={() => {
                    dispatch(
                      allActions.screensActions.setScreenVisibility({
                        isVisible: !item.isVisible,
                        screenIndex: screens.sequence,
                        setIndex: set.sequence,
                        itemIndex: item.sequence,
                      })
                    );
                  }}
                />
              </div>
            );
          });

          // No Set
          return (
            <div key={set.label + "__" + set.index}>
              {set.label != null ? hasHeader : noHeader}
            </div>
          );
        }
      })}
    </Collapse>
  );

  const Menu = (
    <div className={styles.dropdown_chart__control}>
      {screenInsights.map((screens: ScreenInsights) => {
        return (
          <div key={"Dropdown_" + screens.id}>
            {CollapsableHeader(screens)}
            {Options(screens)}
          </div>
        );
      })}
    </div>
  );

  return screenInsights === undefined || screenInsights.length === 0 ? (
    <Loading />
  ) : (
    Menu
  );
};
