import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DD_TITLE } from "../../pages/SurveyInsights/SurveyInsightsVariables";

const CUSTOMER_ID = "customer=<customerID>";
const PROJECT_ID = "project=<projectID>";
const STUDIO_URL = process.env.REACT_APP_STUDIO_URL_LOC + "#/";
const PROJECT_URL = `Project?${CUSTOMER_ID}&${PROJECT_ID}`;
const DATA_URL = `Data?${CUSTOMER_ID}&${PROJECT_ID}`;

export interface Menu {
  name: string;
  link: string;
  icon: JSX.Element;
  options?: Menu[];
}

function Icon(icon: IconProp) {
  return <FontAwesomeIcon icon={icon} size="lg" />;
}

export const HomeMenu: Menu[] = [
  {
    name: "Home",
    link: STUDIO_URL + "Home?" + CUSTOMER_ID,
    icon: Icon(solid("house")),
  },
];

export const SurveyMenu: Menu[] = [
  {
    name: "Survey Overview",
    link: STUDIO_URL + PROJECT_URL,
    icon: <i className="fak fa-solid-chart-tree-map-pen fa-lg" />,
  },
  {
    name: "Survey Settings",
    link: `?${CUSTOMER_ID}&${PROJECT_ID}`,
    icon: Icon(regular("sliders-up")),
    options: [
      {
        name: "Schedule",
        link: STUDIO_URL + `${PROJECT_URL}&form=schedule`,
        icon: Icon(solid("calendar-days")),
      },
      {
        name: "Campaigns",
        link: STUDIO_URL + `${PROJECT_URL}&form=url&tab=url`,
        icon: Icon(solid("fire-flame-curved")),
      },
      {
        name: "Launch Button",
        link: STUDIO_URL + `${PROJECT_URL}&form=launchbtn&tab=launchbtn`,
        icon: Icon(solid("rocket")),
      },
      {
        name: "Survey Detail",
        link: STUDIO_URL + `${PROJECT_URL}&form=order&tab=order`,
        icon: Icon(solid("line-columns")),
      },
      {
        name: "Survey Manager",
        link: STUDIO_URL + `${PROJECT_URL}&form=manager&tab=manager`,
        icon: Icon(solid("user-helmet-safety")),
      },
      {
        name: "Advanced",
        link: STUDIO_URL + `${PROJECT_URL}&tab=advanced`,
        icon: Icon(solid("gear")),
      },
    ],
  },
  {
    name: "Data Center",
    link: STUDIO_URL + DATA_URL,
    icon: Icon(solid("chart-area")),
  },
  {
    name: DD_TITLE,
    link: `DataInsights?${CUSTOMER_ID}&${PROJECT_ID}`,
    icon: Icon(solid("magnifying-glass-chart")),
  },
];

export const AccountMenu: Menu[] = [
  {
    name: "Create Survey",
    link: STUDIO_URL + "Create?" + CUSTOMER_ID,
    icon: Icon(solid("circle-plus")),
  },
  {
    name: "Template Library",
    link: STUDIO_URL + "Templates?" + CUSTOMER_ID,
    icon: Icon(solid("books")),
  },
  {
    name: "User Management",
    link: STUDIO_URL + "#/Roles?" + CUSTOMER_ID,
    icon: Icon(solid("users")),
  },
];

export const SupportMenu: Menu[] = [
  {
    name: "Support",
    link: "https://support.metroquest.com/",
    icon: Icon(solid("question")),
  },
  {
    name: "Screen Guide",
    link: "https://support.metroquest.com/screen-guide/",
    icon: Icon(regular("messages-question")),
  },
];
