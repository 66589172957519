import { Project } from "../../interfaces/insights/project.interface";
import * as ACTION from "../actionConstants";

export const setProject = ({ project }: { project: Project }) => ({
  type: ACTION.SET_PROJECT,
  project: project,
});

export const setIsGeneratingCT = ({
  isGeneratingCT,
}: {
  isGeneratingCT: boolean;
}) => ({
  type: ACTION.SET_IS_GENERATING_CT,
  isGeneratingCT: isGeneratingCT,
});
