import {
  Participant,
  ParticipantRate,
  Platform,
} from "../../interfaces/insights/data.interface";
import * as ACTION from "../actionConstants";

interface ParticipantState {
  participant: Participant | null;
  participantRate: ParticipantRate | null;
  platform: Platform | null;
  hasParticipants: boolean;
}

let init_state: ParticipantState = {
  participant: null,
  participantRate: null,
  platform: null,
  hasParticipants: false,
};

export default function participantReducer(state = init_state, action: any) {
  switch (action.type) {
    case ACTION.SET_PARTICIPANT:
      return {
        ...state,
        participant: action.participant,
      };
    case ACTION.SET_PARTICIPANT_RATE:
      return {
        ...state,
        participantRate: action.participantRate,
      };
    case ACTION.SET_PLATFORM:
      return {
        ...state,
        platform: action.platform,
      };
    case ACTION.SET_HAS_PARTICIPANTS: {
      return {
        ...state,
        hasParticipants: action.hasParticipants,
      };
    }
    default:
      return state;
  }
}
