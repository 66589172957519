import { ScreenDetails } from "./screen.interface";

export const DefaultScreenData: ScreenDetails[] = [
  {
    type: "visit",
    title: "Traffic Data",
    description:
      "The Traffic Data report contains survey traffic data breakdowns by campaign and by day. It also contains a full list of visitors who did not contribute data and their IP addresses.",
    isUpdating: false,
    sequence: 1,
  },
  {
    type: "crosstab",
    title: "Crosstab Data",
    description:
      "The Crosstab Data report contains all answers and questions from the survey in a pivot table format. It does not include all geolocation data provided in Map Marker screens. This data can be downloaded by selecting the Map Marker screen and downloading the Screen Data report.",
    isUpdating: false,
    sequence: 1,
  },
  {
    type: "alldata",
    title: "Session Data",
    description:
      "The Session Data report contains all answers from the survey in a sequential table.",
    isUpdating: false,
    sequence: 1,
  },
  {
    type: "allcomment",
    title: "Comment Data",
    description:
      "The Comment Data report contains all text answers captured in your survey.",
    isUpdating: false,
    sequence: 1,
  },
];

export const ScreenDescription =
  "The Screen Data report contains data from this specific screen only. It enables deeper analysis of a single screen and is particularly important for analysing geolocation data.";
