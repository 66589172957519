import { Project } from "../../interfaces/insights/project.interface";
import * as ACTION from "../actionConstants";

let init_state: Project = {
  id: -1,
  name: "",
  startDate: "",
  endDate: "",
  projectSite: "",
  urls: [],
  screen: -1,
  targetParticipants: -1,
  isGeneratingCT: false,
  siteId: 0,
};

export default function projectReducer(state = init_state, action: any) {
  switch (action.type) {
    case ACTION.SET_PROJECT:
      return {
        ...state,
        id: action.project.id,
        name: action.project.name,
        startDate: action.project.startDate,
        endDate: action.project.endDate,
        projectSite: action.project.projectSite,
        urls: action.project.urls,
        screen: action.screen,
        targetParticipants: action.project.targetParticipants,
        isGeneratingCT: action.project.isGeneratingCT,
        screens: action.project.screens,
        siteId: action.project.siteId,
      };
    case ACTION.SET_IS_GENERATING_CT:
      return {
        ...state,
        isGeneratingCT: action.isGeneratingCT,
      };
    default:
      return state;
  }
}
