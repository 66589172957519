import * as customerActions from "./customerActions";
import * as projectActions from "./projectActions";
import * as screensActions from "./screensActions";
import * as participantsActions from "./participantsActions";

const allActions = {
  customerActions,
  projectActions,
  screensActions,
  participantsActions,
};

export default allActions;
