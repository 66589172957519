import { Customer } from "../../interfaces/insights/customer.interface";
import { Project } from "../../interfaces/insights/project.interface";
import { ReportType } from "../../interfaces/insights/report.type";
import { fetchGetHeader } from "./ApiService";

/**
 * Get customer details
 * @param customerId
 * @returns Customer data
 */
export const getCustomer = async (customerId: number): Promise<Customer> => {
  const url =
    (process.env.REACT_APP_SECURE_URL_LOC as string) +
    `customers?id=${customerId}`;

  const request = await fetchGetHeader();
  const response = await fetch(url, request);

  if (!response.ok) {
    console.log(response);
    throw new Error("getCustomerProject failed");
  }

  const data = await response.json();
  if (data == null) throw new Error(`Customer ${customerId} not found`);
  const customer: Customer = {
    id: data.id,
    name: data.name,
  };

  return customer;
};

/**
 * Get custome project details
 * @param customerId
 * @param projectId
 * @returns project details
 */
export const getProjectAndScreens = async (
  customerId: number,
  projectId: number
) => {
  const url =
    (process.env.REACT_APP_SECURE_URL_LOC as string) +
    `projects?userid=${customerId}&pid=${projectId}`;

  const request = await fetchGetHeader();
  const response = await fetch(url, request);

  if (!response.ok) {
    console.log(response);
    throw new Error("getCustomerProject failed");
  }

  const data = await response.json();
  const prj = data[0];

  if (prj == null) {
    throw new Error(`Project ${projectId} not found`);
  }

  const project: Project = {
    id: prj.id,
    name: prj.name,
    startDate: prj.sites[0].launch + "Z",
    endDate: prj.sites[0].surveyEnd + "Z",
    projectSite: prj.projectSite,
    urls: prj.sites[0].urls,
    screen: prj.screens[0].id,
    targetParticipants: prj.targetParticipants,
    isGeneratingCT: prj.isGeneratingCT,
    siteId: prj.sites[0].id,
  };

  const screens: [] = prj.screens;

  screens.forEach((element: any) => {
    Object.assign(element, { screens: [] });
  });

  return { project, screens };
};

/**
 * Get Polling Status
 * @param projectId
 * @returns reportStatue if array
 */
export const getReportStatus = async ({
  projectId,
}: {
  projectId: number;
}): Promise<ReportType[]> => {
  const url =
    (process.env.REACT_APP_SECURE_URL_LOC as string) +
    `projects?pid=${projectId}`;

  const request = await fetchGetHeader();
  const response = await fetch(url, request);

  if (!response.ok) {
    console.log(response);
    throw new Error("getProject failed");
  }

  const data = await response.json();
  const reportStatue = JSON.parse(data[0].reportStatus);
  if (reportStatue) return reportStatue.isRunning;
  return [];
};
