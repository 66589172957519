import {
  getDataPresentationParams,
  getFilteredSurveyReports,
} from "../../components/pages/SurveyInsights/SurveyInsightsHelpers";
import {
  DefaultScreenData,
  ScreenDescription,
} from "../../interfaces/insights/data.default";
import { Project } from "../../interfaces/insights/project.interface";
import allActions from "../../redux/actions/allActions";
import { store } from "../../redux/store";
import {
  getParticipant,
  getParticipantRate,
  getPlatformBreakdown,
  getScreenContent,
} from "../api/FetchScreenData";
import { getFiles } from "../api/FetchDownloads";
import { getCustomer, getProjectAndScreens } from "../api/FetchProject";
import { pollUpdatingReports } from "../polling/reportsPoller";
import {
  Screens,
  ScreenContentItem,
  ScreenContentSet,
  ScreenDetails,
  ScreenInsights,
  ScreenContent,
} from "../../interfaces/insights/screen.interface";
import { getReportType } from "../../utils/TypeHelper";

/**
 * Get Project Data
 * @param customerId
 * @param projectId
 * @returns Project Data
 */
async function getProjectDetails({
  customerId,
  projectId,
}: {
  customerId: string | null;
  projectId: string | null;
}) {
  if (customerId == null) throw new Error("Missing customer id parameter");
  if (projectId == null) throw new Error("Missing project id parameter");

  const customerData = await getCustomer(parseInt(customerId));
  const { project, screens } = await getProjectAndScreens(
    parseInt(customerId),
    parseInt(projectId)
  );

  document.title = project.name + " - MetroQuest Studio";

  store.dispatch(
    allActions.customerActions.setCustomer({ customer: customerData })
  );
  store.dispatch(allActions.projectActions.setProject({ project: project }));

  store.dispatch(
    allActions.screensActions.setAllScreens({ allScreens: screens })
  );

  return { project: project, allScreens: screens };
}

/**
 * Get Project Reports
 * @param project
 */
async function setSurveyReports({
  project,
  allScreens,
}: {
  project: Project;
  allScreens: Screens[];
}) {
  const files = await getFiles({
    projectId: project.id as number,
  });

  if (files.length < 1) console.log("No Data Inputs yet");

  /**
   * Set the Reports for Traffic, Participants, CrossTab, Session Data etc.
   * @param data [{sequence, createdDate, fileName, type}]
   */
  // Set the Participants, and Traffic Data
  const setTrafficReports = (data: ScreenDetails[]) => {
    const filtedReports = getFilteredSurveyReports({
      sequence: 1,
      data: data,
    });
    const trafficReports = DefaultScreenData;

    if (filtedReports) {
      trafficReports.map((item: ScreenDetails) => {
        const filtered = getFilteredSurveyReports({
          type: item.type,
          data: filtedReports,
        });

        if (filtered && filtered?.length > 0) {
          item.createdDate = filtered[0].createdDate;
          item.fileName = filtered[0].fileName;
        }

        return item;
      });

      // Generated Files
      store.dispatch(
        allActions.screensActions.setTrafficReports({
          trafficReports: trafficReports,
        })
      );
    }
  };

  const setScreensReports = (data: ScreenDetails[]) => {
    // Get screeens not from screen 1
    const getFilteredScreens = () => {
      const filtered: ScreenDetails[] | null = getFilteredSurveyReports({
        sequence: 1,
        data: data,
        notIncluded: true,
      });

      if (filtered && filtered.length > 0) return filtered;
      else return null;
    };

    // Set all the screens with defaul screen reports
    const setScreenReportsFiles = () => {
      const filteredScreens_: Screens[] = allScreens.filter(
        (screen: Screens) => {
          if (screen.sequence > 1) return screen;
          return false;
        }
      );

      const screenReports = filteredScreens_?.map((item) => {
        return {
          title: `Screen ${item.sequence}`,
          sequence: item.sequence,
          description: ScreenDescription,
          isUpdating: false,
          type: getReportType({ type: item.type }),
          id: item.id,
        };
      });

      // Screen Data
      store.dispatch(
        allActions.screensActions.setScreenReports({
          screenReports: screenReports,
        })
      );
    };

    const filteredScreens = getFilteredScreens();
    setScreenReportsFiles();

    // Fill the screensReport with its actual reports if already generated
    if (filteredScreens) {
      filteredScreens.forEach((item: ScreenDetails) => {
        const temp: ScreenDetails = {
          type: item.type,
          sequence: item.sequence,
          isUpdating: false,
          fileName: item.fileName,
          createdDate: item.createdDate,
          title: `Screen ${item.sequence}`,
          description: ScreenDescription,
        };
        store.dispatch(
          allActions.screensActions.modifyScreenReports({
            screenReport: temp,
          })
        );
      });
    }
  };

  // Add screen content to there functions
  setTrafficReports(files);
  setScreensReports(files);

  return { project: project };
}

/**
 * Get Participation Data
 * @param project
 * @returns project ID
 */
async function getParticipantData({ project }: { project: Project }) {
  const participantRateData = await getParticipantRate(
    project.screen as number
  );

  const platformBreakdownData = await getPlatformBreakdown(
    project.screen as number
  );

  const participantData = await getParticipant({
    screenId: project.screen as number,
    targetParticipants: project.targetParticipants as number,
    startDate: project.startDate as string,
    endDate: project.endDate as string,
  });

  store.dispatch(
    allActions.participantsActions.setParticipant({
      participant: participantData,
    })
  );
  store.dispatch(
    allActions.participantsActions.setParticipantRate({
      participantRate: participantRateData,
    })
  );
  store.dispatch(
    allActions.participantsActions.setPlatform({
      platform: platformBreakdownData,
    })
  );

  store.dispatch(
    allActions.participantsActions.setHasParticipants({
      hasParticipants: participantData.actual > 0,
    })
  );

  return { projectId: project.id };
}

async function getScreenInsightsContent() {
  const siteId = store.getState().project.siteId;
  const allScreens = store.getState().screens.allScreens;
  const screenReports = store.getState().screens.screenReports;
  let reportMap = new Map<number, { report: ScreenDetails; index: number }>();

  // Set the  map to link report state to index
  screenReports.forEach((report: ScreenDetails, index: number) => {
    reportMap.set(report.sequence, { report, index });
  });

  // Setup screenInsights
  allScreens.forEach(async (screen: Screens) => {
    // Dont include welcome screen
    if (screen.sequence === 1) return;

    // Remake screen with default value
    let screenInsight: ScreenInsights = {
      ...screen,
      isCollapsed: true,
      reportIndex: reportMap.get(screen.sequence)?.index as number,
      sets: [],
      insight: [],
    };

    const dpDataTitle = getDataPresentationParams({
      type: screenInsight.type,
      setIndex: -99,
      itemIndex: -99,
    });

    // Screen Title
    let screenTitle: ScreenContentSet = {
      properties: { label: "Screen Title" },
      items: [],
      index: 0,
      sequence: 0,
      isActive: true,
      label: "Screen Title",
      parentSet: true,

      // Custom Variables
      isCollapsed: true,
      isVisible: true,
      ...dpDataTitle,
    };

    // Get the screen contents
    const screenContents: ScreenContent = await getScreenContent({
      siteid: siteId,
      screenId: screen.id,
    });

    // Set Screen Title
    if (Object.keys(screenContents.contents).length > 0) {
      screenTitle.properties.label = screenContents.contents.horzTitle;
      screenTitle.label = screenContents.contents.horzTitle;
    }
    screenInsight.sets.push(screenTitle);

    let hasSubsection = screenContents.sets.length > 1;
    let sectionTile = new Map<number, string>();

    screenContents.sets.forEach((set: ScreenContentSet, setIndex: number) => {
      // Remove Socials
      if (screen.type === "XIT" && screenContents.sets.length - 2 <= setIndex)
        return;

      let setDP = getDataPresentationParams({
        type: screen.type,
        setIndex: set.index,
      });

      set = {
        ...set,
        ...setDP,
        settings: screenContents.settings,
        isCollapsed: true,
        isVisible: false,
      };

      // For SCN
      if (screen.type === "SCN") {
        if (setIndex === 0) {
          // Add to set screenInsight
          set.items.forEach((item: ScreenContentItem) => {
            const itemDP = getDataPresentationParams({
              type: screen.type,
              setIndex: set.index,
              itemIndex: item.index,
            });

            const tempSet: ScreenContentSet = {
              ...item,
              ...itemDP,
              items: [],
              isVisible: false,
              isCollapsed: false,
              parentSet: false,
              settings: screenContents.settings,
            };

            screenInsight.sets.push(tempSet);
          });
        }
      }
      //  For categories tale the first set to get category names
      else if (setIndex === 0 && hasSubsection && screen.type !== "XIT") {
        set.items.forEach((item: ScreenContentItem) => {
          sectionTile.set(item.sequence, item.label);
        });
      } else if (setIndex > 0 && screen.type === "IMC") {
        // For IMC Screens
        set.label = sectionTile.get(set.sequence) as string;
        set.items = [];

        screenInsight.sets.push(set);
      } else {
        set.label = sectionTile.get(set.sequence) as string;
        set.items.forEach((item: ScreenContentItem, itemIndex: number) => {
          const itemDP = getDataPresentationParams({
            type: screen.type,
            setIndex: set.index,
            itemIndex: item.index,
            questionType: item.properties.questionType,
          });

          set.items[itemIndex] = {
            ...item,
            ...itemDP,
            isCollapsed: false,
            isVisible: false,
          };
        });

        screenInsight.sets.push(set);
      }
    });

    // Add the screenInsight to the store
    store.dispatch(
      allActions.screensActions.addScreenInsights({
        screenInsights: screenInsight,
      })
    );

    // Sort by sequence number
    store.dispatch(allActions.screensActions.sortScreenInsights());
  });
}

/**
 * Get and setup survey data
 * @param customerId
 * @param projectId
 */
export const setupSurveyInsights = async ({
  customerId,
  projectId,
}: {
  customerId: string | null;
  projectId: string | null;
}) => {
  // if logged in
  await getProjectDetails({ customerId, projectId })
    .then(setSurveyReports)
    .then(getParticipantData)
    .then(pollUpdatingReports)
    .then(getScreenInsightsContent)
    .catch((err: Error) => {
      console.log(err.message);
    });
};
