import React, { useContext } from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { AuthContext } from "../../../context/auth-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import styles from "./UserLogin.module.scss";

const UserLogin = () => {
  const ctx = useContext(AuthContext);
  const { accounts } = useMsal();
  const userAccount = accounts[0] || {};

  return (
    <>
      <AuthenticatedTemplate>
        <UncontrolledDropdown className="ms-auto">
          <DropdownToggle nav caret className={styles.user_dropdown}>
            <FontAwesomeIcon icon={solid("user")} color="white" />
          </DropdownToggle>
          <DropdownMenu right>
            <h5 className="dropdown-item-text mb-0">{userAccount.name}</h5>
            <p className="dropdown-item-text text-muted mb-0">
              {userAccount.username}
            </p>
            <DropdownItem divider />
            <DropdownItem href="mailto:support@metroquest.com">
              <FontAwesomeIcon icon={solid("envelope")} /> Contact Us
            </DropdownItem>
            <DropdownItem onClick={() => ctx.onSignOut()}>
              <FontAwesomeIcon icon={solid("right-from-bracket")} />
              Sign Out
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UncontrolledDropdown className="ms-auto">
          <NavItem className={styles.signin}>
            <NavLink onClick={() => ctx.onSignIn()} href="#">
              Sign in
            </NavLink>
          </NavItem>
        </UncontrolledDropdown>
      </UnauthenticatedTemplate>
    </>
  );
};

export default UserLogin;
