import { ReportType } from "../../interfaces/insights/report.type";
import { fetchGetHeader, fetchPutHeader, getAuthHeaderXML } from "./ApiService";

/**
 * Get Report Files and filename
 * @param projectId
 * @returns files  Array of {sequence, createdDate, fileName, type}
 */
export const getFiles = async ({ projectId }: { projectId: number }) => {
  if (projectId < 0) throw new Error("getFiles Error: Invalid Project Id");

  var url = process.env.REACT_APP_SECURE_URL_LOC as string;
  url += "analysis/files/r?projectid=" + projectId;

  let files: [] = [];
  const request = await fetchGetHeader();
  const response = await fetch(url, request);

  if (!response.ok) {
    throw new Error("getFiles failed");
  }

  const data = await response.json();
  files = data.map((item: any) => {
    item.fileName =
      item.fileName.split("/")[item.fileName.split("/").length - 1];

    return item;
  });

  return files;
};

/**
 * Update/Generate Screen Report
 * @param projectId
 * @param type
 * @param screenId
 * @returns filename string
 */
export const updateReportFile = async ({
  projectId,
  type,
  screenId,
}: {
  projectId: number;
  type: ReportType;
  screenId: number;
}) => {
  let url = process.env.REACT_APP_SECURE_URL_LOC as string;
  url += `analysis/${type}?projectid=${projectId}&screenid=${screenId}`;

  const request = await fetchPutHeader({});
  const response = await fetch(url, request);

  if (!response.ok) {
    throw new Error("updateData failed");
  }

  const data = await response.json();
  if (data.isSuccess === false) {
    console.log(`Unable to Generate ${type} Data`);
    throw new Error("No updateCrosstabData data");
  }

  console.log(`Update ${type} Data Success`);

  let filename =
    data.message.split("\\").length > 0 ? data.message.split("\\")[1] : "type";
  filename = filename + ".xlsx";

  return filename;
};

/**
 * Get report information
 * @param projectId
 * @param type
 * @param screenId
 * @returns report data array buffer
 */
const getReportData = async ({
  projectId,
  type,
  screenId,
}: {
  projectId: number;
  type: ReportType | undefined;
  screenId: number;
}) => {
  let url = process.env.REACT_APP_SECURE_URL_LOC as string;
  url += `analysis/download?report=${type}&projectid=${projectId}&screennum=${screenId}`;
  const header = await getAuthHeaderXML();

  const body = {
    method: "GET",
    headers: header ? header : undefined,
    responseType: "arraybuffer",
  };

  const res = await fetch(url, body);
  if (res.status == null) throw new Error("getReport error");

  return await res.arrayBuffer();
};

/**
 * Download Exel File
 * @param data
 * @param filename
 */
const downloadExcel = ({
  data,
  filename,
}: {
  data: ArrayBuffer;
  filename: string;
}) => {
  var blob = new Blob([data], { type: "" });
  var a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  a.click();

  console.log(`Download ${filename} Complete`);
};

/**
 * Get and download Report File
 * @param projectId
 * @param fileName
 * @param type
 * @param screenId
 */
export const getDownloadReportFile = async ({
  projectId,
  fileName = null,
  type,
  screenId,
}: {
  projectId: number;
  fileName?: string | null;
  type: ReportType;
  screenId: number;
}) => {
  if (projectId < 0) throw new Error("downloadData Error: Invalid Project Id");
  var filename: string | null = fileName;

  try {
    if (!filename) {
      console.log(`Generating ${type} Data...`);
      filename = await updateReportFile({
        projectId,
        type,
        screenId: screenId,
      });
    }

    const data = await getReportData({ projectId, type, screenId: screenId });
    downloadExcel({ data: data, filename: filename as string });
  } catch (err) {
    console.error(`Download ${filename} Not Successful`, err);
  }
};
