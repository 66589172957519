import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "../msal/MsalInstance";

/**
 * Helper function to retrieve token for secure API calls
 * @returns Auth Header with Bearer Token
 */
const getAuthHeader = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    // no account signed in
    console.log("No account signed in");
  } else {
    const accessTokenRequest = {
      account: accounts[0],
      scopes: ["https://metroquest.com/MQManage/user_impersonation"],
    };

    let accessToken = null;
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(
        accessTokenRequest
      );
      accessToken = tokenResponse.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(accessTokenRequest);
      }
    }

    return {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    };
  }
};

/***
 *  @returns Auth Header with Bearer Token for XML Content-Type
 */
export async function getAuthHeaderXML() {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    // no account signed in
    console.log("No account signed in");
  } else {
    const accessTokenRequest = {
      account: accounts[0],
      scopes: ["https://metroquest.com/MQManage/user_impersonation"],
    };

    let accessToken = null;
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(
        accessTokenRequest
      );
      accessToken = tokenResponse.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(accessTokenRequest);
      }
    }

    return {
      Authorization: "Bearer " + accessToken,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
  }
}

/**
 * @returns Get request header
 */
export const fetchGetHeader = async () => {
  const header = await getAuthHeader();
  const request: RequestInit = {
    method: "GET",
    headers: header ? header : undefined,
  };
  return request;
};

/**
 *
 * @param body
 * @returns put request header
 */
export const fetchPutHeader = async (body: any) => {
  const header = await getAuthHeader();
  const request: RequestInit = {
    method: "PUT",
    headers: header ? header : undefined,
    body: JSON.stringify(body),
  };
  return request;
};

/**
 *
 * @param body
 * @returns post request header
 */
export const fetchPostHeader = async (body: any) => {
  const header = await getAuthHeader();
  const request: RequestInit = {
    method: "POST",
    headers: header ? header : undefined,
    body: JSON.stringify(body),
  };
  return request;
};
