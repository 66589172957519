import allActions from "../../redux/actions/allActions";
import { store } from "../../redux/store";
import { getCustomer } from "../api/FetchProject";

/**
 * Sets up customer details and navigation status
 * @param customerId
 */
export const setupNavigation = async ({
  customerId,
}: {
  customerId: string | null;
}) => {
  if (customerId == null) {
    throw new Error("Missing customer id parameter");
  }

  if (customerId != null)
    store.dispatch(
      allActions.customerActions.setCustomer({
        customer: await getCustomer(parseInt(customerId)),
      })
    );
};
