import React, { useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Link.module.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Tooltip } from "reactstrap";

export const Link = ({
  icon,
  title,
  style,
  onClick,
  tooltipNote,
  isLoading = false,
  isDisabled = false,
}: {
  icon?: IconProp;
  title: string;
  style?: string;
  onClick: Function;
  tooltipNote?: JSX.Element | null | string;
  isLoading: boolean;
  isDisabled?: boolean;
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const id = `LinkTooltipTarget${title.replace(/\s/g, "")}`;

  return (
    <div>
      <div
        id={id}
        className={` 
        ${styles.link__button} 
        ${!isLoading && !isDisabled && style ? style : styles.default}
        ${isLoading || isDisabled ? styles.disabled : null}  
      `}
        onClick={
          isLoading || isDisabled
            ? () => {} // Prevent click but keep hover
            : () => {
                Promise.resolve(onClick()).catch((err: string) => {
                  console.error(err);
                });
              }
        }
      >
        {icon ? (
          isLoading ? (
            <FontAwesomeIcon
              icon={solid("circle-notch")}
              spin
              className={`
              ${isLoading ? styles.disabled : style ? style : styles.default}  
            `}
            />
          ) : (
            <FontAwesomeIcon icon={icon} />
          )
        ) : (
          <></>
        )}

        {title}
      </div>
      {tooltipNote ? (
        <Tooltip
          placement="bottom"
          isOpen={tooltipOpen}
          autohide={false}
          target={id}
          toggle={toggle}
          className={styles.link__tooltip}
        >
          {tooltipNote}
        </Tooltip>
      ) : (
        <></>
      )}
    </div>
  );
};
