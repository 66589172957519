import { ReportType, ReportTypeKey } from "../interfaces/insights/report.type";

/**
 *
 * @param type
 * @returns type string
 */
export const getReportType = ({
  type,
}: {
  type: ReportTypeKey | string;
}): ReportType => {
  switch (type) {
    case "INT":
      return "visit";
    case "XIT":
      return "exit";
    case "RNK":
      return "ranking";
    case "STR":
      return "rating";
    case "IMV":
      return "rating";
    case "IMC":
      return "survey";
    case "SRV":
      return "survey";
    case "MRK":
      return "map";
    case "PRJ":
      return "rating";
    case "SCN":
      return "rating";
    case "BDG":
      return "ratio";
    case "CHP":
      return "ratio";
    case "VIS":
      return "comment";
    case "TRD":
      return "rating";
    default:
      return "exit";
  }
};
