import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

const Home = () => {
  return (
    <div>
      <FontAwesomeIcon icon={solid("puzzle")} /> Welcome to MQ Central.
    </div>
  );
};

export default Home;
