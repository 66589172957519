import { fetchGetHeader, fetchPostHeader } from "./ApiService";

/**
 * Get user preference
 * @param customerId
 * @param projectId
 * @param description
 * @returns user preferences
 */
export const getUserPreference = async ({
  customerId,
  projectId = 0,
  description,
}: {
  customerId: number;
  projectId?: number;
  description: string;
}) => {
  const url =
    (process.env.REACT_APP_SECURE_URL_LOC as string) +
    `userPreference?type=UserDefaults` +
    `&customerId=${customerId}` +
    `&projectId=${projectId}` +
    `&description=${description}`;

  const request = await fetchGetHeader();
  const response = await fetch(url, request);

  if (!response.ok) {
    console.log(response);
    throw new Error("getParticipants failed");
  }
  const data = (await response.json())[0];
  return data;
};

/**
 * Updates user preference
 * @param customerId
 * @param projectId
 * @param detail
 * @param description
 * @returns Updated user preference
 */
export const postUserPreference = async ({
  customerId,
  projectId = 0,
  detail,
  description,
}: {
  customerId: string;
  projectId?: number;
  detail: string;
  description: string;
}) => {
  const url =
    (process.env.REACT_APP_SECURE_URL_LOC as string) + `userPreference`;

  const body = {
    customerId: customerId,
    projectId: projectId,
    type: "UserDefaults",
    detail: detail,
    description: description,
  };

  const request = await fetchPostHeader(body);
  const response = await fetch(url, request);

  if (!response.ok) {
    console.log(response);
    throw new Error("postUserPreference failed");
  }
  return response;
};

// Navigation
/**
 * Get Navigation bar state (open/closed)
 * @param customerId
 * @returns get Navigation bar state
 */
export const getNavigationBarDefault = async ({
  customerId,
}: {
  customerId: number;
}) => {
  const response = await getUserPreference({
    customerId: customerId,
    projectId: 0,
    description: "NavigationBarDefault",
  });

  if (!response) {
    console.log(response);
    throw new Error("getNavigationBarDefault failed");
  }

  const showBar: string = response.detail;
  return JSON.parse(showBar);
};

/**
 * Update Navigation bar state (open/closed)
 * @param customerId
 * @param detail
 */
export const postNavigationBarDefault = async ({
  customerId,
  detail,
}: {
  customerId: string;
  detail: boolean;
}) => {
  const response = await postUserPreference({
    customerId: customerId,
    detail: detail.toString(),
    description: "NavigationBarDefault",
  });

  if (!response.ok) {
    console.log(response);
    throw new Error("postNavigationBarDefault failed");
  }
};
