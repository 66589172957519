import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateDay, isOngoing } from "../../../../utils/DateHelpers";
import styles from "./Header.module.scss";
import { DD_TITLE } from "../SurveyInsightsVariables";
import {
  Project,
  ProjectUrl,
} from "../../../../interfaces/insights/project.interface";

export const Header = ({ project }: { project: Project }) => {
  const URLS = project?.urls
    .filter((url: ProjectUrl) => {
      return url.isActive && url.state === "Demo";
    })
    .map((url: ProjectUrl, key: number) => {
      return (
        <div key={key}>
          <a
            href={
              (process.env.REACT_APP_SURVEY_LINK_DEMO as string) + url.urlValue
            }
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            {(process.env.REACT_APP_SURVEY_LINK_DEMO as string) + url.urlValue}
          </a>
          <br />
        </div>
      );
    });

  return (
    <div className={styles.header}>
      <div className={styles.banner}>
        <h1>
          <FontAwesomeIcon icon={solid("magnifying-glass-chart")} size={"xl"} />{" "}
          {DD_TITLE}
        </h1>
      </div>
      <div className={styles.project__container}>
        <div>
          <div className={styles.survey__container}>
            <div className={styles.survey__details}>
              <h2>{project?.name}</h2>
              <div>{URLS}</div>
            </div>
            <div className={styles.filler}></div>
            <div className={styles.survey__date}>
              <div className={styles.survey__date_range}>
                <FontAwesomeIcon icon={solid("calendar-days")} />
                {` ${formatDateDay(
                  project?.startDate as string
                )} - ${formatDateDay(project?.endDate as string)}`}
              </div>
              {isOngoing(project?.endDate as string)
                ? "This survey is ongoing."
                : "The engagement period is over."}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
