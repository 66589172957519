import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Loading.module.scss";

const Loading = () => {
  return (
    <h4 className={styles.container}>
      <FontAwesomeIcon
        icon={solid("circle-notch")}
        spin
        size="sm"
        className={styles.spinner}
      />
      Loading
    </h4>
  );
};

export default Loading;
