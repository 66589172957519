export const SET_PROJECT = "SET_PROJECT";
export const SET_CUSTOMER = "SET_CUSTOMER";

export const SET_IS_GENERATING_CT = "SET_IS_GENERATING_CT";

export const SET_ALL_SCREENS = "SET_ALL_SCREENS";
export const ADD_ALL_SCREENS_SUB_SCREENS = "ADD_ALL_SCREENS_SUB_SCREENS";
export const SET_TRAFFIC_REPORTS = "SET_TRAFFIC_REPORTS";
export const SET_SCREEN_REPORTS = "SET_SCREEN_REPORTS";
export const MODIFY_SCREEN_REPORT = "MODIFY_SCREEN_REPORT";
export const SET_SCREEN_INSIGHTS = "SET_SCREEN_CONTENT";
export const ADD_SCREEN_INSIGHTS = "ADD_SCREEN_CONTENT";
export const TOGGLE_CHART_COLLAPSE = "TOGGLE_CHART_COLLAPSE";
export const TOGGLE_SUB_CHART_COLLAPSE = "TOGGLE_SUB_CHART_COLLAPSE";

export const SORT_SCREEN_INSIGHTS = "SORT_SCREEN_INSIGHTS";
export const SET_SCREEN_REPORT_IS_LOADING = "SET_SCREEN_REPORT_IS_LOADING";
export const SET_CHART_MODE = "SET_CHART_MODE";

export const SET_REPORT_IS_LOADING = "SET_REPORT_IS_LOADING";
export const SET_REPORT_CREATED_DATE = "SET_REPORT_CREATED_DATE";

export const SET_PARTICIPANT = "SET_PARTICIPANT";
export const SET_PARTICIPANT_RATE = "SET_PARTICIPANT_RATE";
export const SET_PLATFORM = "SET_PLATFORM";
export const SET_HAS_PARTICIPANTS = "SET_HAS_PARTICIPANTS";

export const SET_SCREEN_VISIBILITY = "SET_SCREEN_VISIBILITY";
