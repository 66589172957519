import {
  Participant,
  ParticipantRate,
  Platform,
} from "../../interfaces/insights/data.interface";
import {
  ScreenContent,
  ScreenContentItem,
} from "../../interfaces/insights/screen.interface";
import { fetchGetHeader, fetchPutHeader } from "./ApiService";

/**
 * Get Participant Data
 * @param screenId,
 * @param targetParticipants,
 * @param startDate,
 * @param endDate,
 * @returns Participants data
 */
export const getParticipant = async ({
  screenId,
  targetParticipants,
  startDate,
  endDate,
}: {
  screenId: number;
  targetParticipants: number;
  startDate: string;
  endDate: string;
}) => {
  const url =
    (process.env.REACT_APP_SERVICE_URL_LOC as string) +
    `data?t=traffic&groupIndex=-99&setIndex=3&itemIndex=0&variableIndex=-99&userid=0&utco=-8&` +
    `s=${screenId}`;

  const request = await fetchGetHeader();
  const response = await fetch(url, request);

  if (!response.ok) {
    console.log(response);
    throw new Error("getParticipants failed");
  }

  const data = await response.json();
  const participant = await data[0].data[0];
  const trafficURL: string = await getCumulativeTrafficChart(screenId);

  const participants: Participant = {
    actual: parseInt(participant[participant.length - 1]),
    target: targetParticipants,
    startDate: startDate,
    endDate: endDate,
    trafficChatURL: trafficURL,
  };
  return participants;
};

/**
 * Get Participation Rate Data
 * @param screenId
 * @returns participants rate
 */
export const getParticipantRate = async (screenId: number) => {
  const url =
    (process.env.REACT_APP_SERVICE_URL_LOC as string) +
    `data?t=traffic&groupIndex=-99&setIndex=4&itemIndex=0&variableIndex=-99&userid=0&` +
    `s=${screenId}`;

  const request = await fetchGetHeader();
  const response = await fetch(url, request);

  if (!response.ok) {
    console.log(response);
    throw new Error("getParticipants failed");
  }

  const data = await response.json();
  const rate = await data[0].data[0];

  const participants: ParticipantRate = {
    participants: rate[0],
    visitors: rate[1],
  };
  return participants;
};

/**
 * Get Platform Breakdown information
 * @param screenId
 * @returns platform data breakdown
 */
export const getPlatformBreakdown = async (screenId: number) => {
  const url =
    (process.env.REACT_APP_SERVICE_URL_LOC as string) +
    `data?t=traffic&groupIndex=-99&setIndex=5&itemIndex=0&variableIndex=-99&userid=0&` +
    `s=${screenId}`;

  const request = await fetchGetHeader();
  const response = await fetch(url, request);

  if (!response.ok) {
    console.log(response);
    throw new Error("getPlatformBreakdown failed");
  }

  const data = await response.json();
  const breakdown = await data[0].data[0];

  const platform: Platform = {
    web: breakdown[0],
    mobile: breakdown[1],
  };

  return platform;
};

/**
 * Get Participation Traffic Graph
 * @param screenId
 * @returns Cumulative Traffic Chart URL
 */
export const getCumulativeTrafficChart = async (screenId: number) => {
  const url =
    (process.env.REACT_APP_DATA_PRESENTATION_URL_LOC as string) +
    `#!/?t=chart&d=traffic&group=-99&set=3&item=0&variable=-99&v=chart-line&types=&utco=-8&source=v7datacenter&p=3259&ss=1&u=6&` +
    `s=${screenId}`;

  return url;
};

/**
 * Get the Survey Insight Notification
 * @param projectId Project ID
 * @returns survey insight notification details
 */
export const getSurveyInsightNotification = async (projectId: number) => {
  const env = (process.env.REACT_APP_ENVIRONMENT as string).toLocaleLowerCase();
  const url =
    (process.env.REACT_APP_SECURE_URL_LOC as string) +
    `projects?id=${projectId}&name=SurveyInsightData&environment=${env}&version=8`;

  const request = await fetchGetHeader();
  const response = await fetch(url, request);

  if (!response.ok) {
    console.log(response);
    throw new Error("getSurveyInsightNotification failed");
  }

  const data = await response.json();

  if (data.surveyInsightData) return JSON.parse(data.surveyInsightData);
  console.log("missing surveyInsightData");
  return undefined;
};

/**
 * Update Survey Insight Notification data
 * @param projectId
 * @param userId
 * @param warning1
 * @param warning2
 */
export const putSurveyInsightNotification = async ({
  projectId,
  userId,
  warning1 = false,
  warning2 = false,
}: {
  projectId: number;
  userId: number;
  warning1?: boolean;
  warning2?: boolean;
}) => {
  const url =
    (process.env.REACT_APP_SECURE_URL_LOC as string) +
    `projects?id=${projectId}&userId=${userId}&name=SurveyInsightData`;

  const body = {
    participants: {
      warning1: warning1,
      warning2: warning2,
    },
  };

  const request = await fetchPutHeader(body);
  const response = await fetch(url, request);

  if (!response.ok) {
    console.log(response);
    throw new Error("putSurveyInsightNotification failed");
  }

  const data = await response.json();
  if (!data.isSuccess) {
    console.log(data);
    throw new Error("Updating SurveyInsightNotification failed");
  }
};

/**
 * Get the Screen Contents for the screen data
 * @param siteid
 * @param screenId
 * @returns
 */
export const getScreenContent = async ({
  siteid,
  screenId,
}: {
  siteid: number;
  screenId: number;
}): Promise<ScreenContent> => {
  const url =
    (process.env.REACT_APP_CONTENT_URL_LOC as string) +
    `/MqContent/GetScreenContent/?siteid=${siteid}&screenid=${screenId}`;

  const request = await fetchGetHeader();
  const response = await fetch(url, request);

  if (!response.ok) {
    console.log(response);
    throw new Error("getSurveyInsightNotification failed");
  }

  const data = await response.json();

  return { ...data };
};

/**
 *  Created the Graph URL
 * @param  projectId: number;
 * @param  userId: string;
 * @param  sequence: number;
 * @param  screenId: number;
 * @param  screen: ScreenContentItem;
 * @returns
 */
export const getScreenGraphURL = ({
  projectId,
  userId,
  sequence,
  screenId,
  screen,
}: {
  projectId: number;
  userId: string;
  sequence: number;
  screenId: number;
  screen: ScreenContentItem;
}): string => {
  let _projectId: number = projectId;
  let _userId: string = userId;
  let _sequence: number = sequence;
  let _visualType: string = screen.visualType;
  let _screenId: number = screenId;
  let _dataType: string = screen.dataType;
  let _groupIndex: number = screen.groupIndex;
  let _setIndex: number = screen.setIndex;
  let _itemIndex: number = screen.itemIndex;
  let _variableIndex: number = screen.variableIndex;
  let _chartType: string = screen.chartType;
  let _types: string = screen.types;
  let _utcOffset: string | null = null;
  let _mapStyle: string = (screen.properties.questionType as string)
    ? "heatmap"
    : "";
  let _screenMarkers: any[] = [];
  let _uiContent: any = screen;

  let url = (process.env.REACT_APP_DATA_PRESENTATION_URL_LOC as string) + "#!/";

  url += "?t=" + _visualType;
  url += "&s=" + _screenId;
  url += "&d=" + _dataType;

  url += "&group=" + _groupIndex;
  url += "&set=" + _setIndex;
  url += "&item=" + _itemIndex;
  url += "&variable=" + _variableIndex;

  url += "&v=" + _chartType;
  url += "&types=" + _types;
  url += "&source=v7datacenter";

  url += "&p=" + _projectId;
  url += "&ss=" + _sequence;
  url += "&u=" + _userId;

  if (_utcOffset) url += "&utco=" + _utcOffset;

  if (
    _chartType === "map" &&
    _mapStyle !== undefined &&
    _screenMarkers !== undefined &&
    _uiContent !== undefined
  )
    url += getMapURL({
      mapStyle: _mapStyle,
      uiContent: _uiContent,
    });

  return url;
};

// get state for MKR Marker controller,
export const getMapURL = ({
  mapStyle = null,
  uiContent = null,
}: {
  mapStyle?: string | null;
  uiContent?: any;
}) => {
  let mLat = 37.09024; // default values if can't find data
  let mLng = -95.712891;
  let mZoom = 4;
  let mapHasKmz = false;

  if (uiContent.settings) {
    let mapLat =
      uiContent.settings["GoogleMap.mapLat"] || uiContent.settings.lat;
    let mapLng =
      uiContent.settings["GoogleMap.mapLng"] || uiContent.settings.lng;
    let mapZoom =
      uiContent.settings["GoogleMap.mapZoom"] || uiContent.settings.zoom;

    mLat = Number(mapLat);
    mLng = Number(mapLng);
    mZoom = Number(mapZoom);
    mapHasKmz = uiContent.settings["GoogleMap.mapHasKmz"];
  }

  let mapURL = `&mclat=${mLat}&mclng=${mLng}&mzoom=${mZoom}`;
  mapURL += `&mapstyle=${mapStyle}`;

  // KMZ // mapHasKmz
  if (mapHasKmz) {
    var kmzFile;

    if (uiContent.settings) {
      kmzFile = uiContent.settings["GoogleMap.mapKmzFile"] || "Project.kmz";
    } else {
      kmzFile = "Project.kmz";
    }

    mapURL += "&kmzf=" + kmzFile;
  }

  return mapURL;
};
