import { ReportType } from "../../interfaces/insights/report.type";
import {
  Screens,
  ScreenDetails,
  ScreenInsights,
  ScreenContentSet,
} from "../../interfaces/insights/screen.interface";
import * as ACTION from "../actionConstants";

/**
 * Sets the traffic report data
 * @param trafficReports
 * @returns
 */
export const setTrafficReports = ({
  trafficReports,
}: {
  trafficReports: ScreenDetails[];
}) => ({
  type: ACTION.SET_TRAFFIC_REPORTS,
  trafficReports: trafficReports,
});

/**
 * Sets all the screens content Screen -> Set -> Item
 * @param Screen[]  allScreens
 * @returns
 */
export const setAllScreens = ({ allScreens }: { allScreens: Screens[] }) => ({
  type: ACTION.SET_ALL_SCREENS,
  allScreens: allScreens,
});

/**
 * Sets the the screen reports
 * @param ScreenInfo[] screenReports
 * @returns
 */
export const setScreenReports = ({
  screenReports,
}: {
  screenReports: ScreenDetails[];
}) => ({
  type: ACTION.SET_SCREEN_REPORTS,
  screenReports: screenReports,
});

export const modifyScreenReports = ({
  screenReport,
}: {
  screenReport: ScreenDetails;
}) => ({
  type: ACTION.MODIFY_SCREEN_REPORT,
  screenReport: screenReport,
});

/**
 * Sets the report's Loading status
 * @param isLoading
 * @param screenNumber
 * @param screenType
 * @returns
 */
export const setReportIsLoading = ({
  isLoading,
  screenNumber,
  screenType,
}: {
  isLoading: boolean;
  screenNumber?: number;
  screenType?: ReportType;
}) => ({
  type: ACTION.SET_REPORT_IS_LOADING,
  isLoading: isLoading,
  screenNumber: screenNumber,
  screenType: screenType,
});

/**
 * Updates Report Created Date Display
 * @param param0
 * @returns
 */
export const setCreatedDate = ({
  createdDate,
  screenType,
  screenNumber,
  fileName,
}: {
  createdDate: string;
  screenType: ReportType;
  screenNumber: number;
  fileName: string;
}) => ({
  type: ACTION.SET_REPORT_CREATED_DATE,
  createdDate: createdDate,
  screenType: screenType,
  screenNumber: screenNumber,
  fileName: fileName,
});

/**
 * Sets the Screen Visibility
 * @param isVisible
 * @param screen
 * @param insight
 * @returns
 */
export const setScreenVisibility = ({
  isVisible,
  screenIndex,
  setIndex,
  itemIndex = null,
}: {
  isVisible: boolean;
  screenIndex: number;
  setIndex: number;
  itemIndex?: number | null;
}) => ({
  type: ACTION.SET_SCREEN_VISIBILITY,
  isVisible: isVisible,
  screenIndex: screenIndex,
  setIndex: setIndex,
  itemIndex: itemIndex,
});

/**
 * Add the insight screens to the Screens
 * @param screenInsights
 * @returns
 */
export const addScreenInsights = ({
  screenInsights,
}: {
  screenInsights: ScreenInsights;
}) => ({
  type: ACTION.ADD_SCREEN_INSIGHTS,
  screenInsights: screenInsights,
});

/**
 * Toggle the Screen Dropdown status
 * @param screenInsights
 * @returns
 */
export const toggleChartCollapse = ({
  screenInsights,
}: {
  screenInsights: ScreenInsights;
}) => ({
  type: ACTION.TOGGLE_CHART_COLLAPSE,
  screenInsights: screenInsights,
});

/**
 * Toggle the Screen Dropdown status
 * @param screenInsights
 * @returns
 */
export const toggleSubChartCollapse = ({
  set,
  screenInsights,
}: {
  set: ScreenContentSet;
  screenInsights: ScreenInsights;
}) => ({
  type: ACTION.TOGGLE_SUB_CHART_COLLAPSE,
  set: set,
  screenInsights: screenInsights,
});

/**
 * Sorts the screen insights by sequence number
 * @returns
 */
export const sortScreenInsights = () => ({
  type: ACTION.SORT_SCREEN_INSIGHTS,
});

/**
 * Set the chartmode to chart or table
 * @param chartType
 * @param screenNumber
 * @param index
 * @returns
 */
export const setChartMode = ({
  visualType,
  screenIndex,
  setIndex,
  itemIndex,
}: {
  visualType: "chart" | "table";
  screenIndex: number;
  setIndex: number;
  itemIndex?: number | null;
}) => ({
  type: ACTION.SET_CHART_MODE,
  visualType: visualType,
  screenIndex: screenIndex,
  setIndex: setIndex,
  itemIndex: itemIndex,
});
