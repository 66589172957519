import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import styles from "./Link.module.scss";
import { DownloadTooltipLayout } from "../../pages/SurveyInsights/Tooltip/DownloadTooltipLayout";
import { Link } from "./Link";

export const DownloadLink = ({
  title,
  description,
  isUpdating,
  createdDate,
  download,
  update,
  updateDisabled = false,
}: {
  title: string;
  description: string;
  createdDate: string;
  download: Function;
  update: Function;
  isUpdating: boolean;
  updateDisabled?: boolean;
}) => {
  return (
    <Link
      title={title}
      icon={solid("download")}
      style={undefined}
      onClick={() => download()}
      isDisabled={updateDisabled}
      tooltipNote={
        <DownloadTooltipLayout
          body={description}
          date={createdDate}
          update={
            <Link
              title={"Update Data"}
              icon={solid("arrows-rotate")}
              style={styles.button__update}
              onClick={() => update()}
              isLoading={isUpdating}
              isDisabled={updateDisabled}
            />
          }
        />
      }
      isLoading={isUpdating}
    />
  );
};
