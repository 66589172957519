import { formatDateDayAndTime } from "../../../../utils/DateHelpers";
import styles from "./DownloadTooltipLayout.module.scss";

export const TooltipLayout = ({
  body,
  footer,
}: {
  body: JSX.Element;
  footer: JSX.Element;
}) => {
  return (
    <div>
      <div>{body}</div>
      <div className={styles.gap} />
      <div>{footer}</div>
    </div>
  );
};
export const DownloadTooltipLayout = ({
  body,
  date,
  update,
}: {
  body: string;
  date: string;
  update: JSX.Element;
}) => {
  const footer = (
    <div className={styles.footer__container}>
      <div className={styles.footer__date}>
        {date ? (
          <>
            <div> Last Generated: </div>
            <div> {formatDateDayAndTime(date)} </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.filler} />

      <div>{update}</div>
    </div>
  );

  return <TooltipLayout body={<>{body}</>} footer={footer} />;
};
