import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Dropdown as DropdownStrap,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Direction } from "reactstrap/es/Dropdown";
import styles from "./Dropdown.module.scss";

export const Dropdown = ({
  title,
  icon,
  direction,
  menuItems,
  menu,
}: {
  title: string;
  icon?: IconProp;
  direction: Direction;
  menuItems?: JSX.Element[];
  menu?: JSX.Element;
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className={styles.dropdown_wrapper}>
      <DropdownStrap
        isOpen={dropdownOpen}
        toggle={toggle}
        direction={direction}
      >
        <DropdownToggle className={styles.dropdown} caret>
          <div className={styles.dropdown__title}>
            {icon ? <FontAwesomeIcon icon={icon} /> : <></>}
            {title}
          </div>
        </DropdownToggle>

        {/* options jsx [] */}
        {menuItems ? (
          <DropdownMenu>
            {menuItems?.map((element: JSX.Element) => {
              return <DropdownItem>{element}</DropdownItem>;
            })}
          </DropdownMenu>
        ) : (
          <></>
        )}

        {/* Custom Menu */}
        {menu ? (
          <DropdownMenu className={styles.dropdown_menu}>{menu}</DropdownMenu>
        ) : (
          <></>
        )}
      </DropdownStrap>
    </div>
  );
};
