import styles from "./Block.module.scss";

export const Block = ({
  body,
  label,
  icon = null,
  border = null,
  isClickable = false,
}: {
  body: JSX.Element;
  label: string;
  icon?: JSX.Element | null;
  border?: string | null;
  isClickable?: boolean;
}) => {
  return (
    <div
      className={`
    ${styles.block__container} 
    ${
      isClickable ? `${styles.clickable} ${styles.container__border_hover}` : ""
    } 
    ${border}
  `}
    >
      <div className={`${icon ? styles.block__warning : styles.hide}`}>
        {icon}
      </div>
      <div className={styles.block__data}>{body}</div>
      <div className={styles.block__label}>{label}</div>
    </div>
  );
};
