import {
  getDownloadReportFile,
  getFiles,
  updateReportFile,
} from "../../../services/api/FetchDownloads";

import allActions from "../../../redux/actions/allActions";
import { store } from "../../../redux/store";
import { ScreenDetails } from "../../../interfaces/insights/screen.interface";
import { ReportType } from "../../../interfaces/insights/report.type";

// Dispatchers
/**
 * Set download loading state in Store
 * @param isLoading
 * @param sequence
 * @param type
 */
export const setScreenDownloadToLoading = ({
  isLoading,
  sequence,
  type,
}: {
  isLoading: boolean;
  sequence: number;
  type: ReportType;
}) => {
  store.dispatch(
    allActions.screensActions.setReportIsLoading({
      isLoading: isLoading,
      screenNumber: sequence,
      screenType: type,
    })
  );
};

/**
 * update Survey Details in Store
 * @param survey
 */
export const updateSurveyDetails = ({ survey }: { survey: ScreenDetails }) => {
  store.dispatch(
    allActions.screensActions.setCreatedDate({
      createdDate: survey.createdDate as string,
      screenType: survey.type,
      screenNumber: survey.sequence,
      fileName: survey.fileName as string,
    })
  );
};

// Report Filters ------------------------------------------------
/**
 * Filters Survey Report pased on sequence, type
 * @param sequence
 * @param type
 * @param data
 * @param notIncluded default false
 * @returns Filtered Survey Reports
 */
export const getFilteredSurveyReports = ({
  sequence,
  type,
  data,
  notIncluded = false,
}: {
  sequence?: number;
  type?: string;
  data: ScreenDetails[];
  notIncluded?: boolean;
}): ScreenDetails[] | null => {
  try {
    const filtered = data.filter((item: any) => {
      if (notIncluded) {
        if (
          sequence &&
          type &&
          item.sequence !== sequence &&
          item.type !== type
        ) {
          return item;
        } else if (!type && sequence && item.sequence !== sequence) {
          return item;
        } else if (!sequence && type && item.type !== type) {
          return item;
        }
        return false;
      } else {
        if (
          sequence &&
          type &&
          item.sequence === sequence &&
          item.type === type
        ) {
          return item;
        } else if (!type && sequence && item.sequence === sequence) {
          return item;
        } else if (!sequence && type && item.type === type) {
          return item;
        }
        return false;
      }
    });

    if (!filtered) console.error("No Data Found for");

    return filtered;
  } catch (err) {
    console.error(err);
    return null;
  }
};

/**
 * Filters Report Details pased on sequence, type
 * @param sequence
 * @param type
 * @param data
 * @param notIncluded default false
 * @returns Filtered Reports Details
 */
export const getFilteredReportDetails = ({
  sequence,
  type,
  data,
  notIncluded = false,
}: {
  sequence?: number;
  type?: string;
  data: ScreenDetails[];
  notIncluded?: boolean;
}): ScreenDetails[] | null => {
  try {
    const filtered = data.filter((item: any) => {
      if (notIncluded) {
        if (
          sequence &&
          type &&
          item.sequence !== sequence &&
          item.type !== type
        ) {
          return item;
        } else if (!type && sequence && item.sequence !== sequence) {
          return item;
        } else if (!sequence && type && item.type !== type) {
          return item;
        }
        return false;
      } else {
        if (
          sequence &&
          type &&
          item.sequence === sequence &&
          item.type === type
        ) {
          return item;
        } else if (!type && sequence && item.sequence === sequence) {
          return item;
        } else if (!sequence && type && item.type === type) {
          return item;
        }
        return false;
      }
    });

    if (!filtered) console.error("No Data Found for");

    return filtered;
  } catch (err) {
    console.error(err);
    return null;
  }
};

// Update Details ----------------------------------------
/**
 * Update Survey Report Details
 * @param projectId
 * @param sequence
 * @param type
 */
export const updateReportDetails = async ({
  projectId,
  sequence,
  type,
}: {
  projectId: number;
  sequence: number;
  type: ReportType;
}) => {
  const surveys = await getFiles({
    projectId: projectId,
  });

  const updatedData: ScreenDetails[] | null = getFilteredSurveyReports({
    data: surveys,
    sequence: sequence,
    type: type,
  });
  if (updatedData == null) {
    console.log("updatedData not found");
    return;
  }

  updateSurveyDetails({
    survey: updatedData[0],
  });
};

// Report Download ----------------------------------------
/**
 * Start download animation, download report file, then stop animation
 * @param projectId
 * @param report
 */
export const downloadReport = async ({
  projectId,
  report,
}: {
  projectId: number;
  report: ScreenDetails;
}) => {
  setScreenDownloadToLoading({
    isLoading: true,
    sequence: report.sequence,
    type: report.type as ReportType,
  });
  getDownloadReportFile({
    projectId: projectId,
    fileName: report.fileName,
    type: report.type as ReportType,
    screenId: report.sequence,
  })
    .then(() => {
      if (report.sequence !== 1)
        updateReportDetails({
          projectId: projectId,
          type: report.type as ReportType,
          sequence: report.sequence,
        });
    })
    .then(() =>
      setScreenDownloadToLoading({
        isLoading: false,
        sequence: report.sequence,
        type: report.type as ReportType,
      })
    );
};

// Report Update -------------------------------------------
/**
 * Start update animation, update report file, then stop animation
 * @param projectId
 * @param report
 */
export const updateReports = async ({
  projectId,
  report,
}: {
  projectId: number;
  report: ScreenDetails;
}) => {
  setScreenDownloadToLoading({
    isLoading: true,
    sequence: report.sequence,
    type: report.type as ReportType,
  });
  updateReportFile({
    projectId: projectId,
    type: report.type as ReportType,
    screenId: report.sequence,
  })
    .then(async () => {
      if (report.sequence !== 1)
        updateReportDetails({
          projectId: projectId,
          type: report.type as ReportType,
          sequence: report.sequence,
        });
    })
    .then(() =>
      setScreenDownloadToLoading({
        isLoading: false,
        sequence: report.sequence,
        type: report.type as ReportType,
      })
    );
};

// Get Sequence from Type
/**
 * get sequence number from type
 * @param type
 * @returns sequence number
 */
export const getSequenceFromType = (type: ReportType): number => {
  const screen: ScreenDetails[] = store.getState().screens.screenReports;

  for (let i = 0; i < screen.length; i++) {
    if (type === screen[i].type) return screen[i].sequence;
  }

  return 1;
};

export function getDataPresentationParams({
  type,
  setIndex = -99,
  itemIndex = -99,
  screenType = null,
  questionType = null,
}: {
  type: string;
  setIndex?: number;
  itemIndex?: number;
  screenType?: "comment" | null;
  questionType?: string | null;
}) {
  const defaultIndex = -99;
  let groupIndex_ = defaultIndex;
  let variableIndex_ = defaultIndex;
  let setIndex_ = defaultIndex;
  let itemIndex_ = defaultIndex;
  let visualType_: "table" | "chart" | "map" | "wordcloud" | "kpi" = "chart";
  let dataType_ = "category";
  let chartType_ = "chart-bar";

  if (screenType === "comment") {
    return {
      groupIndex: groupIndex_,
      variableIndex: variableIndex_,
      setIndex: setIndex_,
      itemIndex: itemIndex_,
      visualType: visualType_,
      chartType: chartType_,
      dataType: "comment",
      types: "comment,text",
    };
  } else {
    switch (type) {
      // Zipcode:Map, input: cloud
      case "XIT":
        switch (questionType) {
          case "textarea": // comment
            visualType_ = "wordcloud";
            dataType_ = "comment";
            chartType_ = "wordcloud";
            groupIndex_ = 1;
            setIndex_ = 1;
            itemIndex_ = itemIndex;
            break;

          case "textbox": // textbox
            visualType_ = "wordcloud";
            dataType_ = "text";
            chartType_ = "wordcloud";
            groupIndex_ = 1;
            setIndex_ = 1;
            itemIndex_ = itemIndex;
            break;

          //FIXME:
          case "zipcode":
            visualType_ = "map";
            dataType_ = "zipcode";
            chartType_ = "map";
            groupIndex_ = 1;
            setIndex_ = 1;
            itemIndex_ = itemIndex;
            break;

          default:
            visualType_ = "chart";
            dataType_ = "category";
            chartType_ = "chart-bar";
            groupIndex_ = 1;
            setIndex_ = itemIndex === defaultIndex ? 1 : setIndex;
            itemIndex_ = itemIndex;
            break;
        }
        break;

      // Screen 01-03------------------------------------------------------------

      case "RNK": // Priority Ranking
        dataType_ = "ranking";
        chartType_ =
          itemIndex === defaultIndex ? "chart-bar-average" : "chart-bar";
        groupIndex_ = 1;
        setIndex_ = 1;
        itemIndex_ = itemIndex;
        break;

      case "STR": // Strategy Rating
        dataType_ = "rating";
        chartType_ =
          setIndex !== defaultIndex && itemIndex === defaultIndex
            ? "chart-bar-average"
            : "chart-bar";
        groupIndex_ = setIndex === defaultIndex ? groupIndex_ : 1;
        setIndex_ = setIndex;
        itemIndex_ = itemIndex;
        break;

      case "IMV": // Image Rating
        dataType_ = "rating";
        chartType_ =
          setIndex !== defaultIndex && itemIndex === defaultIndex
            ? "chart-bar-average"
            : "chart-bar";
        groupIndex_ = setIndex === defaultIndex ? groupIndex_ : 1;
        setIndex_ = setIndex;
        itemIndex_ = itemIndex;
        break;

      // Screen 04-06------------------------------------------------------------
      case "IMC": // Visual Preference
        dataType_ = "category";
        chartType_ = "chart-bar";
        itemIndex_ = setIndex === setIndex_ ? setIndex_ : setIndex - 100;
        groupIndex_ = 1;
        setIndex_ = 1;
        break;

      case "TRD": // Tradeoffs
        dataType_ = "rating";
        chartType_ =
          itemIndex === defaultIndex ? "chart-bar-average" : "chart-bar";
        groupIndex_ = 1;
        setIndex_ = 1;
        itemIndex_ = itemIndex;
        break;

      /**
       * senario rating
       * Section out the by scenario
       */
      case "SCN": // Scenario Rating
        dataType_ = "rating";
        chartType_ =
          itemIndex === defaultIndex ? "chart-bar-average" : "chart-bar";
        groupIndex_ = 1;
        setIndex_ = 1;
        itemIndex_ = itemIndex;
        break;

      // Screen 07-09------------------------------------------------------------

      // Map View
      // FIXME:
      case "MRK": // Map Marker
        //  have to make a marker controller in the FE
        break;

      case "PRJ": // Project Selection
        dataType_ = "category";
        chartType_ = "chart-bar";
        groupIndex_ = 1;
        setIndex_ = 1;
        itemIndex_ = itemIndex;
        break;

      case "BDG":
        dataType_ = "ratio";
        chartType_ =
          itemIndex === itemIndex_ ? "chart-bar-average-alone" : "chart-bar";
        groupIndex_ = 1;
        setIndex_ = 1;
        itemIndex_ = itemIndex;
        break;

      // Screen 10-12------------------------------------------------------------
      case "CHP": // Budget Allocation
        dataType_ = "ratio";
        chartType_ =
          itemIndex === itemIndex_ ? "chart-bar-average-alone" : "chart-bar";
        groupIndex_ = 1;
        setIndex_ = 1;
        itemIndex_ = itemIndex;
        break;

      // TODO: Later, fix the wordcloud/sentiment
      case "VIS": // Vision Statement
        dataType_ = itemIndex === itemIndex_ ? "comment" : "wordcloud";
        chartType_ = itemIndex === itemIndex_ ? "chart-bar" : "wordcloud";
        visualType_ = itemIndex === itemIndex_ ? "chart" : "wordcloud";
        groupIndex_ = 1;
        setIndex_ = 1;
        itemIndex_ = itemIndex;
        break;

      case "SRV": // Standard Survey
        dataType_ = itemIndex === itemIndex_ ? "category" : "text";
        chartType_ = itemIndex === itemIndex_ ? "chart-bar" : "wordcloud";
        visualType_ = itemIndex === itemIndex_ ? "chart" : "wordcloud";
        groupIndex_ = setIndex === setIndex_ ? groupIndex_ : 1;
        setIndex_ = setIndex;
        itemIndex_ = itemIndex;
        break;
    }
  }

  return {
    groupIndex: groupIndex_,
    variableIndex: variableIndex_,
    setIndex: setIndex_,
    itemIndex: itemIndex_,
    visualType: visualType_,
    chartType: chartType_,
    dataType: dataType_,
    types: "",
  };
}
