import { Customer } from "../../interfaces/insights/customer.interface";
import * as ACTION from "../actionConstants";

let init_state: Customer = {
  id: -1,
  name: "",
};

export default function customerReducer(state = init_state, action: any) {
  switch (action.type) {
    case ACTION.SET_CUSTOMER:
      return {
        ...state,
        id: action.id,
        name: action.name,
      };
    default:
      return state;
  }
}
