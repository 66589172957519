import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom";
import UserLogin from "./UserLogin";
import "../../../styles/utilities.scss";
import styles from "./Header.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

interface Prop {
  children: any;
  toggle: Function;
}

const Header = (props: Prop) => {
  return (
    <header>
      <Navbar className={styles.navbar}>
        <div className={styles.hamburger_icon} onClick={() => props.toggle()}>
          <FontAwesomeIcon icon={solid("bars")} size="lg" />
        </div>
        <NavbarBrand
          tag={Link}
          to="/"
          className={`${styles.navbar__brand}  navbar-right`}
        >
          <img
            className={styles.navbar__logo}
            alt="mq-logo"
            src={
              "https://mqstoragedev.blob.core.windows.net/services/mq_invert.png"
            }
          />
          MetroQuest Studio
        </NavbarBrand>
        <UserLogin {...props.children} />
        <span className={styles["navbar-environment-label"]}>
          {process.env.REACT_APP_ENVIRONMENT}
        </span>
      </Navbar>
    </header>
  );
};

export default Header;
