import { getReportStatus } from "../api/FetchProject";
import { store } from "../../redux/store";
import {
  getSequenceFromType,
  setScreenDownloadToLoading,
} from "../../components/pages/SurveyInsights/SurveyInsightsHelpers";
import { ScreenDetails } from "../../interfaces/insights/screen.interface";
import { ReportType } from "../../interfaces/insights/report.type";

/**
 * Checks if data update is completed
 * @param projectId
 * @returns
 */
export const pollUpdatingReports = async ({
  projectId,
}: {
  projectId: number;
}) => {
  const runningReports: ReportType[] = await getReportStatus({ projectId });

  if (runningReports.length > 0) {
    runningReports.map((type: ReportType) => {
      setScreenDownloadToLoading({
        isLoading: true,
        sequence: getSequenceFromType(type),
        type: type,
      });
      return type;
    });
  } else {
    const screenReports: ScreenDetails[] =
      store.getState().screens.screenReports;
    const trafficReports: ScreenDetails[] =
      store.getState().screens.trafficReports;

    screenReports.map((screen: ScreenDetails) => {
      setScreenDownloadToLoading({
        isLoading: false,
        sequence: screen.sequence,
        type: screen.type,
      });
      return screen;
    });

    trafficReports.map((report: ScreenDetails) => {
      setScreenDownloadToLoading({
        isLoading: false,
        sequence: report.sequence,
        type: report.type,
      });
      return report;
    });
  }

  // Continue Polling if not done generating
  if (runningReports.length > 0) {
    console.log("Polling...");
    console.log("Stil Generating file");
    setTimeout(() => pollUpdatingReports({ projectId: projectId }), 10000);
  }

  return;
};
