import React from "react";
import styles from "./ParticipantData.module.scss";
import { DownloadLink } from "../../../buttons/link/DownloadLink";
import {
  Participants,
  ParticipationRate,
  PlatformBreakdown,
} from "./ParticipantData";
import {
  Participant,
  ParticipantRate,
  Platform,
} from "../../../../interfaces/insights/data.interface";
import { useSelector } from "react-redux";
import {
  downloadReport,
  getFilteredReportDetails,
  updateReports,
} from "../SurveyInsightsHelpers";
import { ScreenDetails } from "../../../../interfaces/insights/screen.interface";

export const Participation = () => {
  const project = useSelector((state: any) => state.project);
  const customer = useSelector((state: any) => state.customer);
  const participants = useSelector((state: any) => state.participants);
  const screens = useSelector((state: any) => state.screens);

  const participant: Participant = participants.participant;
  const participantRate: ParticipantRate = participants.participantRate;
  const platform: Platform = participants.platform;

  const hasParticipants = useSelector(
    (state: any) => state.participants
  ).hasParticipants;

  const trafficReports: ScreenDetails[] = getFilteredReportDetails({
    type: "visit",
    data: screens.trafficReports,
  }) as ScreenDetails[];

  return (
    <div className={styles.data__container}>
      <div className={`${styles.data__header}`}>
        <h4>Participants</h4>
        <div className={styles.filler}></div>
        {trafficReports ? (
          trafficReports.map((screen: ScreenDetails, key: number) => {
            return (
              <DownloadLink
                key={key}
                title={screen.title as string}
                description={screen.description as string}
                createdDate={screen.createdDate as string}
                isUpdating={screen.isUpdating}
                download={() =>
                  downloadReport({ projectId: project.id, report: screen })
                }
                update={() =>
                  updateReports({ projectId: project.id, report: screen })
                }
                updateDisabled={!hasParticipants}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
      <div className={`${styles.data__content} ${styles.participation_data}`}>
        <Participants
          participant={participant}
          projectId={project.id}
          customerId={customer.id}
        />
        <ParticipationRate
          participants={participantRate?.participants}
          visitors={participantRate?.visitors}
        />
        <PlatformBreakdown web={platform?.web} mobile={platform?.mobile} />
      </div>
    </div>
  );
};
