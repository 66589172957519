import React, { useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./GraphView.module.scss";
import { Modal } from "../../modal/Modal";
import { colorIsDark } from "../../../utils/ColorHelper";
import { UncontrolledTooltip } from "reactstrap";

export const GraphView = ({
  title,
  subTitle,
  graphLink,
  detail,
  isVisible,
  setVisibility,
  color,
  target = null,
}: {
  title: string;
  subTitle: string;
  graphLink: string;
  detail?: JSX.Element;
  isVisible?: boolean;
  setVisibility: Function;
  color?: string;
  target?: string | null;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const toggle = async () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleTooltip = () => {
    setIsTooltipOpen(true);
    setTimeout(() => setIsTooltipOpen(false), 1000);
  };

  const ModalHeader = (
    <div className={styles.modal__title}>
      <FontAwesomeIcon icon={solid("chart-area")} className="m-0" size="lg" />
      <h3 className={styles.modal__title_text}>{title}</h3>
    </div>
  );

  const ModalBody = (
    <iframe className={styles.chart} src={graphLink} title={title}></iframe>
  );

  const Title = (
    <div>
      <div className={styles.graph__header_title}>{title}</div>
      <div
        className={styles.graph__header_subTitle}
        style={{
          backgroundColor: color ? color : "gray",
          color: colorIsDark(color as string) ? "white" : "black",
        }}
      >
        {subTitle}
      </div>
    </div>
  );

  const Options = (
    <div className={styles.graph__header_options}>
      <FontAwesomeIcon
        icon={solid("link")}
        onClick={() => {
          navigator.clipboard.writeText(graphLink);
          toggleTooltip();
        }}
        title={"Copy Graph Link"}
        id={target as string}
        style={{ outline: "none" }}
      />
      <FontAwesomeIcon
        icon={solid("eye-slash")}
        onClick={() => setVisibility()}
        title={"Hide"}
      />
      <FontAwesomeIcon
        icon={solid("expand")}
        onClick={() => toggle()}
        title={"Expand"}
      />
      {target ? (
        <UncontrolledTooltip
          placement="top"
          target={target}
          fade={true}
          isOpen={isTooltipOpen}
          className={styles.copy_tooltip}
        >
          Graph Link Coppied
        </UncontrolledTooltip>
      ) : null}
    </div>
  );

  const Footer = <div className={styles.graph__footer}>{detail}</div>;

  return (
    <div
      className={`${styles.graph__wrapper}  ${styles.container__border_hover} 
                  ${isVisible ? styles.graph__display : styles.hide}`}
    >
      <Modal
        header={ModalHeader}
        body={ModalBody}
        isOpen={isModalOpen}
        toggle={toggle}
        headerStyle={styles.bg_light_gray}
        footer={Footer}
      />

      <div className={styles.graph__header}>
        {Title}
        <div className={styles.filler}></div>
        {Options}
      </div>
      <div className={styles.graph__body}>
        <iframe
          className={styles.graph__container}
          src={graphLink}
          title={title}
        ></iframe>
      </div>
      {Footer}
    </div>
  );
};
