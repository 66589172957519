import {
  Screens,
  ScreenDetails,
  ScreenInsights,
  ScreenContentSet,
} from "../../interfaces/insights/screen.interface";
import * as ACTION from "../actionConstants";

interface State {
  allScreens: Screens[];
  trafficReports: ScreenDetails[]; // Screen 1
  screenReports: ScreenDetails[]; // Screen 2 <
  screenInsights: ScreenInsights[];
}

let init_state: State = {
  allScreens: [],
  trafficReports: [],
  screenReports: [],
  screenInsights: [],
};

export default function screensReducer(state = init_state, action: any) {
  switch (action.type) {
    case ACTION.SET_ALL_SCREENS:
      return {
        ...state,
        allScreens: action.allScreens,
      };

    case ACTION.SET_TRAFFIC_REPORTS:
      return {
        ...state,
        trafficReports: action.trafficReports,
      };

    case ACTION.SET_SCREEN_REPORTS:
      return {
        ...state,
        screenReports: action.screenReports,
      };
    case ACTION.MODIFY_SCREEN_REPORT:
      let newScreenReports = state.screenReports;
      let index = newScreenReports.findIndex(
        (screenDetail: ScreenDetails) =>
          screenDetail.sequence === action.screenReport.sequence
      );
      newScreenReports[index] = action.screenReport;
      return {
        ...state,
        screenReports: newScreenReports,
      };

    case ACTION.SET_SCREEN_INSIGHTS:
      return {
        ...state,
        screenInsights: action.screenInsights,
      };

    case ACTION.ADD_SCREEN_INSIGHTS:
      return {
        ...state,
        screenInsights: [...state.screenInsights, action.screenInsights],
      };

    case ACTION.SET_REPORT_IS_LOADING:
      if (action.screenNumber === 1) {
        return {
          // if a traffic or summary reports
          ...state,
          trafficReports: state.trafficReports.map((item) =>
            item.type === action.screenType &&
            item.sequence === action.screenNumber
              ? { ...item, isUpdating: action.isLoading }
              : { ...item }
          ),
        };
      } else {
        // If screen related reports
        let screenReport = state.screenReports.map((item) =>
          item.type === action.screenType &&
          item.sequence === action.screenNumber
            ? { ...item, isUpdating: action.isLoading }
            : { ...item }
        );

        // Updated both report and survey insight data
        return {
          ...state,
          screenReports: screenReport,
        };
      }

    case ACTION.SET_REPORT_CREATED_DATE:
      if (action.screenNumber === 1) {
        return {
          ...state,
          trafficReports: state.screenReports.map((item) =>
            item.type === action.screenType &&
            item.sequence === action.screenNumber
              ? {
                  ...item,
                  createdDate: action.createdDate,
                  filename: action.filename,
                }
              : { ...item }
          ),
        };
      } else {
        let screenReports = state.screenReports.map((item) =>
          item.type === action.screenType &&
          item.sequence === action.screenNumber
            ? {
                ...item,
                createdDate: action.createdDate,
                filename: action.filename,
              }
            : { ...item }
        );

        return {
          ...state,
          screenReports: screenReports,
        };
      }

    case ACTION.SORT_SCREEN_INSIGHTS:
      let sorted = state.screenInsights;

      // Sort Screen by sequence number
      sorted.sort((a: ScreenInsights, b: ScreenInsights) =>
        a.sequence > b.sequence ? 1 : b.sequence > a.sequence ? -1 : 0
      );

      return {
        ...state,
        screenInsights: sorted,
      };

    case ACTION.TOGGLE_CHART_COLLAPSE: {
      const screenIndex: number = state.screenInsights.findIndex(
        (screen) => screen! === action.screenInsights
      );

      const newScreenInsight = [...state.screenInsights];
      newScreenInsight[screenIndex].isCollapsed =
        !newScreenInsight[screenIndex].isCollapsed;

      return {
        ...state,
        screenInsights: newScreenInsight,
      };
    }

    case ACTION.TOGGLE_SUB_CHART_COLLAPSE: {
      const screenIndex: number = state.screenInsights.findIndex(
        (screen) => screen! === action.screenInsights
      );

      const setIndex: number = action.screenInsights.sets.findIndex(
        (set: ScreenContentSet) => set! === action.set
      );

      const newScreenInsight = [...state.screenInsights];

      newScreenInsight[screenIndex].sets[setIndex].isCollapsed =
        !newScreenInsight[screenIndex].sets[setIndex].isCollapsed;

      return {
        ...state,
        screenInsights: newScreenInsight,
      };
    }

    case ACTION.SET_SCREEN_VISIBILITY: {
      const newScreenInsight = [...state.screenInsights];

      if (action.itemIndex) {
        newScreenInsight[action.screenIndex - 2].sets[action.setIndex].items[
          action.itemIndex - 1
        ].isVisible = action.isVisible;
      } else {
        newScreenInsight[action.screenIndex - 2].sets[
          action.setIndex
        ].isVisible = action.isVisible;
      }

      return {
        ...state,
        screenInsights: newScreenInsight,
      };
    }

    case ACTION.SET_CHART_MODE:
      const newScreenInsight = [...state.screenInsights];

      if (action.itemIndex) {
        newScreenInsight[action.screenIndex - 2].sets[action.setIndex].items[
          action.itemIndex - 1
        ].visualType = action.visualType;
      } else {
        newScreenInsight[action.screenIndex - 2].sets[
          action.setIndex
        ].visualType = action.visualType;
      }

      return {
        ...state,
        screenInsights: newScreenInsight,
      };

    default:
      return state;
  }
}
