import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import styles from "./Checkbox.module.scss";

export const Checkbox = ({
  title = "",
  defaultChecked,
  onChange,
}: {
  title?: string;
  defaultChecked?: boolean;
  onChange?: Function;
}) => {
  return (
    <FormGroup check>
      <Input
        type="checkbox"
        checked={defaultChecked}
        onChange={onChange ? () => onChange() : () => {}}
        className={styles.clickable}
      />
      <Label
        check
        className={styles.clickable}
        onClick={onChange ? () => onChange() : () => {}}
      >
        {title}
      </Label>
    </FormGroup>
  );
};
