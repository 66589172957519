import {
  Participant,
  ParticipantRate,
  Platform,
} from "../../interfaces/insights/data.interface";
import * as ACTION from "../actionConstants";

export const setParticipant = ({
  participant,
}: {
  participant: Participant;
}) => ({
  type: ACTION.SET_PARTICIPANT,
  participant: participant,
});

export const setParticipantRate = ({
  participantRate,
}: {
  participantRate: ParticipantRate;
}) => ({
  type: ACTION.SET_PARTICIPANT_RATE,
  participantRate: participantRate,
});

export const setPlatform = ({ platform }: { platform: Platform }) => ({
  type: ACTION.SET_PLATFORM,
  platform: platform,
});

export const setHasParticipants = ({
  hasParticipants,
}: {
  hasParticipants: boolean;
}) => ({
  type: ACTION.SET_HAS_PARTICIPANTS,
  hasParticipants: hasParticipants,
});
