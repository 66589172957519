import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal as ModalStrap,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import styles from "./Modal.module.scss";

export const Modal = ({
  header,
  body,
  footer,
  isOpen,
  toggle,
  headerStyle = null,
}: {
  header: JSX.Element;
  body: JSX.Element;
  footer?: JSX.Element | null;
  isOpen: boolean;
  toggle: () => void;
  headerStyle?: string | null;
}) => {
  const closeBtn = (
    <Button
      className={`${styles.button_close} `}
      onClick={toggle}
      type="button"
    >
      <FontAwesomeIcon icon={solid("x")} className={"m-0"} />
    </Button>
  );

  return (
    <ModalStrap
      isOpen={isOpen}
      toggle={toggle}
      className={`${styles.modal} `}
      centered
      size="xl"
    >
      <ModalHeader
        toggle={toggle}
        close={closeBtn}
        className={`${styles.modal__header} ${headerStyle}`}
      >
        {header}
      </ModalHeader>
      <ModalBody className={`${styles.modal__body}`}>{body}</ModalBody>
      {footer ? (
        <ModalFooter className={`${styles.modal__footer}`}>
          {footer}
        </ModalFooter>
      ) : (
        <></>
      )}
    </ModalStrap>
  );
};
