import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Navigation } from "../navigation/navigation/Navigation";
import Header from "../navigation/header/Header";
import styles from "./Layout.module.scss";
import { useLocation } from "react-router";
import {
  getNavigationBarDefault,
  postNavigationBarDefault,
} from "../../services/api/FetchGeneral";

interface Prop {
  children: any;
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Layout = (props: Prop) => {
  const query = useQuery();
  const customerId = query.get("customer");
  const [navOpen, setNavOpen] = useState(false);

  const toggle = async () => {
    setNavOpen(!navOpen);
    if (customerId)
      await postNavigationBarDefault({
        customerId: customerId,
        detail: !navOpen,
      });
  };

  useEffect(() => {
    const getNavbarActive = async () => {
      if (customerId == null) throw new Error("Missing customer id parameter");
      const isOpen: boolean = await getNavigationBarDefault({
        customerId: parseInt(customerId),
      });
      setNavOpen(isOpen);
    };
    getNavbarActive();
  }, [customerId]);

  return (
    <div className={styles.layout}>
      <Header toggle={toggle} {...props.children} />
      <AuthenticatedTemplate>
        <div
          className={`${styles.layout__container} ${
            navOpen ? styles.active : ""
          }`}
        >
          <div
            className={` ${navOpen ? styles.active : ""} ${
              styles.layout__side_nav
            }`}
          >
            <Navigation toggle={toggle} isActive={navOpen} />
          </div>
          <div className={"spacer"}></div>
          <div
            className={`${styles.layout__content} ${
              navOpen ? styles.active : ""
            }`}
          >
            <div className={styles.layout__content_items}>{props.children}</div>
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Container>
          <p>Please sign in</p>
        </Container>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default Layout;
