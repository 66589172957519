import React from "react";
import "./styles/overrides.scss";
import { Route } from "react-router";
import { MsalProvider } from "@azure/msal-react";

import Layout from "./components/layout/Layout";
import Home from "./components/pages/Home/Home";
import { AuthContextProvider } from "./context/auth-context";

import SurveyInsights from "./components/pages/SurveyInsights/SurveyInsights";
import { store } from "./redux/store";
import { Provider } from "react-redux";

export default function App({ msalInstance }: any) {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthContextProvider>
        <Provider store={store}>
          <Layout>
            <Route exact path="/" component={Home} />
            <Route path={"/DataInsights"} exact component={SurveyInsights} />
          </Layout>
        </Provider>
      </AuthContextProvider>
    </MsalProvider>
  );
}
