const option_day: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const option_day_time: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

/**
 *
 * @param date
 * @returns formatted date YYYY-MM-DD HH:mm:ss
 */
export const formatDate = (date: string) => {
  return new Date(date)
    .toISOString()
    .replace(/T/, " ") // replace T with a space
    .replace(/\..+/, "");
};

/**
 *
 * @param date
 * @returns formated date = Mmm DD, YYYY
 */
export const formatDateDay = (date: string) => {
  const dateFormat = new Date(date);
  return dateFormat.toLocaleDateString("en-US", option_day);
};

/**
 *
 * @param date
 * @returns formatted data = Mmm DD, YYYY, HH:MM PM
 */
export const formatDateDayAndTime = (date: string) => {
  const dateFormat = new Date(date);
  return dateFormat.toLocaleDateString("en-US", option_day_time);
};

/**
 *
 * @param date
 * @returns boolen if survey is ongoing
 */
export const isOngoing = (date: string): boolean => {
  const today = new Date();
  const endDate = new Date(date);
  return today < endDate;
};

/**
 * Converts date to number
 * @param date
 * @returns date in number formats
 */
export const convertDateToNum = (date: Date) => {
  return date.getTime() / (1000 * 3600 * 24);
};

/**
 * Get the number of days passed since launch date
 * @param start
 * @param end
 * @returns number of days passed
 */
export const getDaysPassed = ({ start, end }: { start: Date; end: Date }) => {
  return convertDateToNum(end) - convertDateToNum(start);
};
