export const msalConfig = {
  auth: {
    authority: "https://login.microsoftonline.com/metroquest.com",
    clientId: "968cd108-bb67-41ae-a1c8-9be279fbf64d",
    redirectUri: document.getElementById("root")?.baseURI,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored,
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
