import { useEffect, useState } from "react";
import { Block } from "../../../data/block/Block";
import { Modal } from "../../../modal/Modal";
import styles from "./ParticipantData.module.scss";
import { getDaysPassed } from "../../../../utils/DateHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getFooterMessage } from "../../../modal/Footer";
import {
  Status,
  SurveyInsightData,
  Timeline,
  WARNING,
  Warnings,
} from "../SurveyInsightsVariables";
import {
  getSurveyInsightNotification,
  putSurveyInsightNotification,
} from "../../../../services/api/FetchScreenData";
import { Participant } from "../../../../interfaces/insights/data.interface";

const getParticipationWarning = ({
  participant,
  timeline_,
}: {
  participant: Participant;
  timeline_: Timeline;
}): [Warnings, string | undefined] => {
  // No Target specified, No warnings
  if (
    participant.target < 1 ||
    timeline_ === "Graceday" ||
    participant == null
  ) {
    return ["Default", ""];
  }

  let warning: Warnings = "Default";
  let status: Status = "Default";
  let message: string = "";

  const startDate = new Date(participant?.startDate);
  const endDate = new Date(participant?.endDate);

  const daysActive = getDaysPassed({ start: startDate, end: endDate });
  const daysPassed = getDaysPassed({
    start: startDate,
    end: new Date(),
  });

  const targetParticipants = participant?.target;
  const currentParticipants = participant?.actual;
  const targetCurrentRate = (targetParticipants / daysActive) * daysPassed;

  if (timeline_ === "Closed") {
    if (currentParticipants >= targetParticipants) {
      warning = "Good";
      status = "Finished";
    } else {
      warning = "Warning";
      status = "Finished";
    }
  } else {
    if (currentParticipants >= targetParticipants) {
      warning = "Good";
      status = "OngoingMetTarget";
    } else if (currentParticipants >= targetCurrentRate) {
      warning = "Good";
      status = "Ongoing";
    } else {
      warning = "Warning";
      status = "Ongoing";
    }
  }
  const participantPerc = (
    (currentParticipants / targetParticipants) *
    100
  ).toFixed(2);
  message = (WARNING[warning].message[status] as string)
    .replace("<percent>", participantPerc.toString())
    .replace("<target>", targetParticipants.toString())
    .replace("<current>", currentParticipants.toString());

  return [warning, message];
};

const getTimeline = ({
  start,
  end,
}: {
  start: string;
  end: string;
}): Timeline => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const daysActive = getDaysPassed({ start: startDate, end: endDate });
  const daysPassed = getDaysPassed({
    start: startDate,
    end: new Date(),
  });

  // Check if within grace period to avoid showing warnings
  const gracePeriod = daysActive / 3;
  const wrapupPeriod = (daysActive / 3) * 2;

  if (daysPassed < gracePeriod) {
    return "Graceday";
  } else if (gracePeriod <= daysPassed && daysPassed < wrapupPeriod) {
    return "Mid";
  } else if (wrapupPeriod <= daysPassed && daysPassed < daysActive) {
    return "Closing";
  } else {
    return "Closed";
  }
};

/**
 *
 * @param surveyInsightData
 * @param timeline
 * @returns [showBorder_, showNotification_]
 */
const getNotification = ({
  surveyInsightData,
  timeline,
}: {
  participant: Participant;
  surveyInsightData: SurveyInsightData;
  timeline: Timeline;
}): [boolean, boolean] => {
  if (!surveyInsightData) return [false, false];

  const data = surveyInsightData;

  switch (timeline) {
    case "Graceday":
      return [false, false];

    case "Mid":
      return [true, data ? data.participants.warning1 : false];

    case "Closing":
      return [true, data ? data.participants.warning2 : false];
    case "Closed":
      return [true, data ? data.participants.warning2 : false];

    default:
      return [true, false];
  }
};

export const Participants = ({
  participant,
  projectId,
  customerId,
}: {
  participant: Participant;
  projectId: number;
  customerId: number;
}) => {
  const [warning, setWarning] = useState<Warnings>("Default");
  const [warningMessage, setWarningMessage] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showBorder, setShowBorder] = useState(false);
  const [timeline, setTimeline] = useState<Timeline>();
  const [surveyInsightData, setSurveyInsightData] =
    useState<SurveyInsightData>();

  const toggle = async () => {
    setIsModalOpen(!isModalOpen);

    if (showNotification) {
      setShowNotification(false);

      switch (timeline) {
        case "Mid":
          if (surveyInsightData?.participants.warning1)
            putSurveyInsightNotification({
              projectId: projectId,
              userId: customerId,
              warning1: false,
              warning2: true,
            });
          break;
        case "Closing":
          putSurveyInsightNotification({
            projectId: projectId,
            userId: customerId,
            warning1: false,
            warning2: false,
          });
          break;
        case "Closed":
          putSurveyInsightNotification({
            projectId: projectId,
            userId: customerId,
            warning1: false,
            warning2: false,
          });
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    const getSurveyNotifications = async () => {
      if (participant == null) return;

      const timeline_ = getTimeline({
        start: participant?.startDate,
        end: participant?.endDate,
      });

      const [warnWarning, warnMessage] = getParticipationWarning({
        participant,
        timeline_,
      });

      let [showBorder_, showNotification_] = [false, false];
      const surveyInsightData_ = await getSurveyInsightNotification(projectId);

      if (surveyInsightData_) {
        [showBorder_, showNotification_] = getNotification({
          participant: participant,
          surveyInsightData: surveyInsightData_,
          timeline: timeline_,
        });
      }

      console.log("\n--- Survey Insight Notification Status");
      console.log(
        "Participants: ",
        participant.actual,
        "/",
        participant.target
      );
      console.log("Time Period: ", timeline_);
      console.log("\t", showBorder_ ? "Show Border" : "Hide Border");
      console.log(
        "\t",
        showNotification_ ? "Show Notification" : "Hide Notification"
      );

      setSurveyInsightData(surveyInsightData_);
      setTimeline(timeline_);
      setWarning(warnWarning);
      setWarningMessage(warnMessage);
      setShowBorder(showBorder_);
      setShowNotification(showNotification_);
    };

    getSurveyNotifications();
  }, [participant, projectId]);

  const Header = (
    <div className={`${styles.modal__title}`}>
      <FontAwesomeIcon icon={solid("chart-area")} className="m-0" size="lg" />
      <h3 className={`${styles.modal__title_text}`}>Participation Traffic</h3>
    </div>
  );

  const Body = (
    <iframe
      className={styles.chart}
      src={participant ? participant.trafficChatURL : ""}
      title="Participation Traffic"
    ></iframe>
  );

  const Footer =
    warning !== "Default"
      ? getFooterMessage({
          message: (
            <div className={styles.footer_message}>{warningMessage}</div>
          ),
          color: WARNING[warning].color,
          background: WARNING[warning].backgroundLight,
        })
      : null;

  const Data = (
    <div className={styles.participation__large_text}>
      {participant ? participant.actual : "0"}
    </div>
  );

  return (
    <div onClick={toggle}>
      <Modal
        header={Header}
        body={Body}
        footer={Footer}
        isOpen={isModalOpen}
        toggle={toggle}
        headerStyle={`${WARNING["Default"].backgroundLight}`}
      />

      <Block
        body={Data}
        label="Participants"
        border={
          showBorder ? WARNING[warning].border : WARNING["Default"].border
        }
        isClickable={true}
        icon={showNotification ? WARNING[warning].icon : null}
      />
    </div>
  );
};

const Legend = ({ text, barColor }: { text: string; barColor: string }) => {
  return (
    <div className={styles.participation__legend}>
      <div className={`${styles.participation__legend_bar} ${barColor}`}></div>
      <div className={styles.participation__legend_text}>{text}</div>
    </div>
  );
};

export const ParticipationRate = ({
  participants = "0",
  visitors = "0",
}: {
  participants: string;
  visitors: string;
}) => {
  const data = (
    <div className={styles.participation}>
      {Legend({
        text: "Participants (%): " + participants,
        barColor: styles.bar_blue,
      })}
      {Legend({
        text: "Visitors (%): " + visitors,
        barColor: styles.bar_default,
      })}
    </div>
  );
  return <Block body={data} label="Participation Rate" />;
};

export const PlatformBreakdown = ({
  web = "0",
  mobile = "0",
}: {
  web: string;
  mobile: string;
}) => {
  const webLabel = Legend({
    text: "Web (%): " + web,
    barColor: styles.bar_blue,
  });
  const mobileLabel = Legend({
    text: "Mobile (%): " + mobile,
    barColor: styles.bar_green,
  });
  const data = (
    <div className={styles.participation}>
      {webLabel}
      {mobileLabel}
    </div>
  );
  return <Block body={data} label="Platform Breakdown" />;
};
