import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { Collapse, Nav, NavItem } from "reactstrap";
import { setupNavigation } from "../../../services/setup/setupNavigation";
import { DD_TITLE } from "../../pages/SurveyInsights/SurveyInsightsVariables";
import {
  Menu,
  HomeMenu,
  SurveyMenu,
  AccountMenu,
  SupportMenu,
} from "./MenuItems";
import styles from "./Navigation.module.scss";

interface Prop {
  isActive: boolean;
  toggle: Function;
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Navigation = (props: Prop) => {
  const query = useQuery();
  const customerId = query.get("customer");
  const projectId = query.get("project");
  const customer = useSelector((state: any) => state.customer);

  useEffect(() => {
    setupNavigation({ customerId: customerId }).catch((err: Error) => {
      console.log(err.message);
    });
  }, [customerId]);

  const CategoryHeader = (header: string) => {
    return (
      <div
        className={`${styles.nav__category} ${
          props.isActive ? "" : styles.hide
        }`}
      >
        {header}
      </div>
    );
  };

  const Border = () => {
    return <div className={styles.border}></div>;
  };

  const MenuItem = ({
    item,
    key,
    isSub = false,
    externalURL = false,
  }: {
    item: Menu;
    key: number;
    isSub?: boolean;
    externalURL?: boolean;
  }) => {
    const url = item.link
      .replace(/<customerID>/gi, customerId as string)
      .replace(/<projectID>/gi, projectId as string);
    return (
      <NavItem key={key} className={styles.nav__item}>
        <NavLink
          exact
          to={item.name === DD_TITLE ? url : { pathname: url }}
          target={externalURL ? "_blank" : "_parent"}
          className={`${styles.nav__link} ${
            isSub && props.isActive ? "ps-5" : ""
          }`}
          activeClassName={styles.nav__link_active}
        >
          {item.icon}
          <span className={props.isActive ? "" : styles.hide}>{item.name}</span>
        </NavLink>
      </NavItem>
    );
  };

  const SubMenu = (category: Menu, key?: number) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggle = () => setCollapsed(!collapsed);

    return (
      <div key={key}>
        <NavItem onClick={toggle} className={styles.nav__item}>
          <NavLink
            className={`${styles.nav__link} ${styles.dropdown_icon} `}
            to={category.link
              .replace(/<customerID>/gi, customerId as string)
              .replace(/<projectID>/gi, projectId as string)}
          >
            <span className={`${styles.dropdown_icon}`}>
              <FontAwesomeIcon
                icon={collapsed ? solid("caret-right") : solid("caret-down")}
              />
              {category.icon}
            </span>
            <span className={props.isActive ? "" : styles.hide}>
              {category.name}
            </span>
          </NavLink>
        </NavItem>

        <Collapse isOpen={!collapsed} navbar>
          {category.options?.map((item, key) =>
            MenuItem({ item, key, isSub: true })
          )}
        </Collapse>
      </div>
    );
  };

  const MenuCategory = (menuItems: Menu[]) => {
    return menuItems.map((item: Menu, key: number) => {
      if (item.options) {
        return SubMenu(item, key);
      } else if (item.name === "Support" || item.name === "Screen Guide") {
        return MenuItem({
          item,
          key,
          externalURL: true,
        });
      } else if (item.name === "Data Center") {
        return MenuItem({
          item,
          key,
        });
      } else {
        return MenuItem({
          item,
          key,
        });
      }
    });
  };

  return (
    <div className={styles.navigation}>
      <Nav vertical className="list-unstyled pb-3">
        <div className={props.isActive ? styles.nav__user : styles.hide}>
          {customer?.name}
        </div>
        {MenuCategory(HomeMenu)}
        {Border()}
        {CategoryHeader("Survey")}
        {MenuCategory(SurveyMenu)}
        {Border()}
        {CategoryHeader("Account")}
        {MenuCategory(AccountMenu)}
        {Border()}
        {MenuCategory(SupportMenu)}
      </Nav>
    </div>
  );
};
