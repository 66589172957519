import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Loading from "../../loading/Loading";
import styles from "./SurveyInsights.module.scss";
import { useSelector } from "react-redux";
import { Header } from "./Header/Header";
import { Participation } from "./Participation/Participation";
import { Insights } from "./Insights/Insights";
import { setupSurveyInsights } from "../../../services/setup/setupSurveyInsights";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SurveyInsights = () => {
  const project = useSelector((state: any) => state.project);
  const query = useQuery();
  const customerId = query.get("customer");
  const projectId = query.get("project");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const setup = async () => {
      await setupSurveyInsights({ customerId, projectId });
      setIsLoading(false);
    };
    setup();
  }, [customerId, projectId]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.container}>
      <Header project={project} />
      <div className={styles.divider}></div>
      <div className={styles.container__data}>
        <Participation />
      </div>
      <div className={styles.divider}></div>
      <div className={styles.container__data}>
        <Insights project={project} />
      </div>
    </div>
  );
};

export default SurveyInsights;
