import styles from "./Footer.module.scss";

export const getFooterMessage = ({
  message,
  background = null,
  color = null,
}: {
  message: JSX.Element | null;
  background?: string | null;
  color?: string | null;
}) => {
  return (
    <div className={`${styles.message}`}>
      <div
        className={`${styles.message__background} ${
          background ? background : styles.default__bg
        } `}
      >
        <div className={`${color} ${styles.message__container}`}>{message}</div>
      </div>
    </div>
  );
};
