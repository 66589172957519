import React from "react";
import styles from "./Insights.module.scss";
import { DownloadLink } from "../../../buttons/link/DownloadLink";
import { Project } from "../../../../interfaces/insights/project.interface";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadReport,
  getFilteredReportDetails,
  updateReports,
} from "../SurveyInsightsHelpers";
import { Dropdown } from "../../../input/dropdown/Dropdown";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { GraphView } from "../../../data/graph/GraphView";
import { ChartVisibilityMenu } from "./ChartVisibilityMenu";
import {
  ScreenContentItem,
  ScreenContentSet,
  ScreenDetails,
  ScreenInsights,
} from "../../../../interfaces/insights/screen.interface";
import Loading from "../../../loading/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import allActions from "../../../../redux/actions/allActions";
import { getScreenGraphURL } from "../../../../services/api/FetchScreenData";

/**
 * Insights Data Section
 * @param project: Project Type
 * @returns
 */
export const Insights = ({ project }: { project: Project }) => {
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.customer);

  const trafficReports: ScreenDetails[] = useSelector(
    (state: any) => state.screens
  ).trafficReports;

  const screenReports: ScreenDetails[] = useSelector(
    (state: any) => state.screens
  ).screenReports;

  const screenInsights: ScreenInsights[] = useSelector(
    (state: any) => state.screens
  ).screenInsights;

  const reportsArray: ScreenDetails[] | null = getFilteredReportDetails({
    type: "visit",
    data: trafficReports,
    notIncluded: true,
  });

  const surveyDownload: ScreenDetails[] = reportsArray ? reportsArray : [];

  const hasParticipants = useSelector(
    (state: any) => state.participants
  ).hasParticipants;

  // Footer with Graph Type (chart/table) and donwload button
  const GraphFooter = ({
    report,
    screenIndex,
    setIndex,
    itemIndex = null,
    item,
  }: {
    report: ScreenDetails;
    screenIndex: number;
    setIndex: number;
    itemIndex: number | null;
    item: ScreenContentItem;
  }) => {
    const VisualType = (
      <div className={styles.chart__type}>
        <FontAwesomeIcon
          icon={solid("chart-line-up")}
          className={item.visualType === "chart" ? styles.icon__selected : ""}
          onClick={() => {
            dispatch(
              allActions.screensActions.setChartMode({
                visualType: "chart",
                screenIndex: screenIndex,
                setIndex: setIndex,
                itemIndex: itemIndex ? itemIndex : null,
              })
            );
          }}
        />
        <FontAwesomeIcon
          icon={solid("table")}
          className={item.visualType === "table" ? styles.icon__selected : ""}
          onClick={() => {
            dispatch(
              allActions.screensActions.setChartMode({
                visualType: "table",
                screenIndex: screenIndex,
                setIndex: setIndex,
                itemIndex: itemIndex ? itemIndex : null,
              })
            );
          }}
        />
      </div>
    );

    return (
      <div className={styles.graph_footer}>
        {item.visualType === "chart" || item.visualType === "table" ? (
          VisualType
        ) : (
          <></>
        )}

        <div className={styles.filler} />
        {report ? (
          <DownloadLink
            title={report.title as string}
            description={report.description as string}
            createdDate={report.createdDate as string}
            isUpdating={report.isUpdating}
            download={() =>
              downloadReport({ projectId: project.id, report: report })
            }
            update={() =>
              updateReports({
                projectId: project.id,
                report: report,
              })
            }
            updateDisabled={!hasParticipants}
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  // Section Title with Data Download (Crosstab, Session, and Comments)
  const Header = (
    <div className={`${styles.data__header}`}>
      <h4>Screen Data</h4>
      <div className={styles.filler}></div>
      <div className={`${styles.data__downloads}`}>
        {surveyDownload.map((report: ScreenDetails) => {
          return (
            <DownloadLink
              key={"DownloadLink_" + report.title}
              title={report.title as string}
              description={report.description as string}
              createdDate={report.createdDate as string}
              isUpdating={report.isUpdating}
              download={() =>
                downloadReport({ projectId: project.id, report: report })
              }
              update={() =>
                updateReports({ projectId: project.id, report: report })
              }
              updateDisabled={!hasParticipants}
            ></DownloadLink>
          );
        })}
      </div>
    </div>
  );

  // Chart Control for visibility
  const Controls = (
    <div className={styles.graph_control}>
      <Dropdown
        direction={"down"}
        title={"Available Charts"}
        icon={solid("square-poll-vertical")}
        menu={<ChartVisibilityMenu />}
      />
    </div>
  );

  // Graph View Component
  const Graph = ({
    screens,
    set,
    item = null,
  }: {
    screens: ScreenInsights;
    set: ScreenContentSet;
    item?: ScreenContentItem | null;
  }) => {
    const content = item ? item : set;
    const keyname = item
      ? `Screen_${screens.sequence}_Set_${set.index}_Item_${item.index}`
      : `Screen_${screens.sequence}_Set_${set.index}`;

    return (
      <GraphView
        color={screens.backgroundColor}
        title={content.label as string}
        subTitle={screens.title}
        target={keyname + "_copy"}
        graphLink={
          content.isVisible
            ? getScreenGraphURL({
                projectId: project.id,
                userId: user.id,
                sequence: screens.sequence,
                screenId: screens.id,
                screen: content,
              })
            : ""
        }
        key={keyname}
        isVisible={content.isVisible}
        setVisibility={() => {
          dispatch(
            allActions.screensActions.setScreenVisibility({
              isVisible: !content.isVisible,
              screenIndex: screens.sequence,
              setIndex: set.sequence,
              itemIndex: item ? content.sequence : null,
            })
          );
        }}
        detail={GraphFooter({
          report: screenReports[screens.reportIndex],
          item: content,
          screenIndex: screens.sequence,
          setIndex: set.sequence,
          itemIndex: item ? content.sequence : null,
        })}
      />
    );
  };

  // List of Graph View Components
  let GraphList: JSX.Element[] = [];

  // Put the Graphs in tehe Graph Component
  screenInsights?.forEach((screens: ScreenInsights) => {
    screens.sets.forEach((set: ScreenContentSet) => {
      GraphList.push(Graph({ screens, set }));
      set.items.forEach((item: ScreenContentItem) => {
        GraphList.push(Graph({ screens, set, item }));
      });
    });
  });

  return (
    <div className={styles.data__container}>
      {Header}

      {!hasParticipants ||
      screenReports.length === 0 ||
      trafficReports.length === 0 ? (
        <div className={`${styles.no_data} `}>No Data</div>
      ) : (
        <>
          {Controls}
          {screenInsights.length === 0 ? (
            <Loading />
          ) : (
            <div className={`${styles.graph_container} `}>
              {GraphList.map((graph: JSX.Element) => {
                return graph;
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};
