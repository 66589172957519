import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SurveyInsights.module.scss";

export type Warnings = "Good" | "Warning" | "Default";
export type Status = "Ongoing" | "Finished" | "OngoingMetTarget" | "Default";
export const DD_TITLE = "Survey Insights";
export type Timeline = "Graceday" | "Mid" | "Closing" | "Closed";

// Participation Warnings --------------

type WarningProps = {
  color: string;
  background: string;
  backgroundLight: string;
  border: string;
  message: {
    Ongoing: string;
    Finished: string;
    OngoingMetTarget?: string;
    Default?: "";
  };
  icon: JSX.Element | null;
};

type WarningObjects = {
  Good: WarningProps;
  Warning: WarningProps;
  Default: WarningProps;
};

export const WARNING: WarningObjects = {
  Good: {
    color: styles.text__good,
    background: styles.background__good,
    backgroundLight: styles.background__good_light,
    border: styles.border__good,
    message: {
      Ongoing:
        "Your survey is on its way to reaching your participant. So far, you are at <percent>% of your original target of <target> participants.",
      OngoingMetTarget:
        "Great job! Your survey has already reached your participant target. You engaged <current> people, which is <percent>% of your original target of <target> participants.",
      Finished:
        "Congratulations! Your survey reached your participant target. You engaged <current> people, which is <percent>% of your original goal of <target> participants.",
    },
    icon: (
      <div className={`${styles.icon__good}`}>
        <FontAwesomeIcon icon={solid("circle-check")} className="m-0" />
      </div>
    ),
  },
  Warning: {
    color: styles.text__warning,
    background: styles.background__warning,
    backgroundLight: styles.background__warning_light,
    border: styles.border__warning,
    message: {
      Ongoing:
        "If the participant traffic remains the same, you may not reach your participant target. So far, you are at <percent>% of your original target of <target> participants.",
      Finished:
        "Your survey did not reach your participant target. You engaged <current> people, which is <percent>% of your original goal of <target> participants.",
    },
    icon: (
      <div className={`${styles.icon__warning}`}>
        <FontAwesomeIcon icon={solid("circle-exclamation")} className="m-0" />
      </div>
    ),
  },
  Default: {
    color: styles.text,
    background: styles.background,
    backgroundLight: styles.background_light,
    border: styles.border,
    message: {
      Ongoing: "",
      Finished: "",
    },
    icon: null,
  },
};

// Participants Data Type
export interface SurveyInsightData {
  participants: {
    warning1: boolean;
    warning2: boolean;
  };
}
