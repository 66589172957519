import { combineReducers } from "redux";
import projectReducer from "./projectReducers";
import customerReducer from "./customerReducers";
import screensReducer from "./screensReducers";
import participantsReducers from "./participantsReducers";

export const allReducers = combineReducers({
  customer: customerReducer,
  project: projectReducer,
  screens: screensReducer,
  participants: participantsReducers,
});
